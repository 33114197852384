import { Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const parseJwt = (token) => {
    try {
        return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
        return null;
    }
};

function PrivateRoute({ children }) {
    const decodedJwt = parseJwt(Cookies.get('accessToken'));
    // if (!decodedJwt || decodedJwt.exp * 1000 < Date.now()) {
    //     Cookies.remove('accessToken')
    //     return <Navigate to="/login" />
    // }
    return children;
}
export default PrivateRoute;