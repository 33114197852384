import { Combobox, Menu } from '@headlessui/react'
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline'
import React from 'react'

const SearchCustom = (props) => {
    return (
        <Combobox>
            <div className="relative">
                <div className="relative">
                    <Combobox.Input
                        className="w-96 pl-9 bg-white placeholder:font-medium"
                        placeholder={props.placeholder}
                        onChange={(event) => props.setQuery(event.target.value)}
                    />
                    <MagnifyingGlassIcon className="h-4 w-5 absolute left-2 top-2" />
                    <Combobox.Button
                        className="absolute inset-y-0 right-0 flex items-center pr-1"
                    >
                        {props.query !== '' &&
                            <XMarkIcon
                                className="h-4 w-5 text-primary-900 stroke-2"
                                aria-hidden="true"
                                onClick={() => props.setQuery('')}
                            />
                        }
                    </Combobox.Button>
                </div>
            </div>
        </Combobox>
    )
}

export default SearchCustom