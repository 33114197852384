import React, { useEffect, useState } from 'react';
import { headerMenus } from '../utils/constant';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Icon from './Icon';
import './../styles/sidebar.scss';
import MedusaLogo from './../assets/medusa.png';
import SideMenuArrow from './SideMenuArrow';
import { useDispatch, useSelector } from 'react-redux';
import { updateMenuName, updateSubMenuName } from '../ConfigureStore/sidebarMenu';

const Sidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isSidebar, setIsSidebar] = useState(false);
  const sidebarMenu = useSelector((state) => state.sidebarMenu)
  const checkActiveRoute = (subMenu) => {
    return sidebarMenu?.isShowSubMenu?.toLowerCase() === subMenu?.toLowerCase();
  }
  return (
    <>
      <div className="lg:flex hidden fixed h-full z-20 " onMouseLeave={() => {
        setIsSidebar(false)
      }} >
        <div
          className={`sidebar ${isSidebar ? `w-[240px]` : `w-[50px]`}`}
          onMouseEnter={() => setIsSidebar(true)}
          onMouseLeave={() => { setIsSidebar(false); }}
        >
          <div className="header">
            <img style={{ width: '24px' }} src={MedusaLogo} alt="medusa-logo" />
            <h3 className="text-lg truncate">MEDUSA APP</h3>
          </div>
          <div className="border-b border-b-primary-700"> </div>
          {
            headerMenus.map((item, key) => {
              return (
                <>
                  <ul>
                    <li
                      onClick={() => { dispatch(updateMenuName({ isShowMenu: item?.name })); navigate(item.href) }}
                      className={`${location.pathname.includes(item.href) ? "active" : ""}`}
                      Key={key}
                    >
                      <div >
                        <Icon home={item.icon} width={'20px'} height={'20px'}/>
                      </div>
                      <span>{item.name}</span>
                    </li>
                    {
                      (
                        <div
                          className={`sidebar-extended ${(item?.submenu?.length > 0 && sidebarMenu?.isShowMenu === item?.name && isSidebar) ? 'h-full' : 'h-0 hidden'}`}
                        >
                          <ul>
                            {
                              item.submenu && item.submenu.map((item, key) => {
                                return (
                                  <li
                                    key={key}
                                    onClick={() => { navigate(item.href); dispatch(updateSubMenuName({ isShowSubMenu: item?.name })) }}
                                    className={`${checkActiveRoute(item?.name) ? `active` : ''}`}>
                                    {checkActiveRoute(item?.name) ? <SideMenuArrow /> : <div className='pl-5'></div>}
                                    <div className='arrowMaker'>
                                      <span>{item.name}</span>
                                    </div>
                                  </li>
                                )
                              })
                            }
                          </ul>
                        </div>
                      )
                    }
                  </ul >
                </>
              )
            })
          }
        </div >
      </div >
    </>

  )
}

export default Sidebar