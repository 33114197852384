
import Smarty from './api';

export const smartySearch = async (payload) => {
    return await Smarty.autocomplete.search(payload);
}







