import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isShowMenu: null,
    isShowSubMenu: null,
}

export const sidebarMenu = createSlice({
    name: 'sidebarMenu',
    initialState,
    reducers: {
        updateMenuName(state, action) {
            state.isShowMenu = action.payload.isShowMenu
            state.isShowSubMenu = null;
        },
        updateSubMenuName(state, action) {
            state.isShowSubMenu = action.payload.isShowSubMenu
        }
    },
})

// Action creators are generated for each case reducer function

export const { updateMenuName, updateSubMenuName } = sidebarMenu.actions
export default sidebarMenu.reducer