import { QueryClient } from "react-query"

let medusaServer = process.env.REACT_APP_SERVER_URL

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 90000,
      retry: 1,
    },
  },
})

export { medusaServer, queryClient }
