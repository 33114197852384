import React from 'react'

const SideMenuArrow = (props) => {
    return (
        <div className='p-0 m-0 ml-[5px]'>
            <svg className='text-white arrow-icon z-20 p-0 m-0' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width="15" height="15">
                <path d="M 7.8 3.9 L 15.6 11.7 L 7.8 19.5 M 15.6 11.7 L 7.8 11.7 C 0.78 11.7 0.78 7.8 0.78 3.9 L 0.78 0" />
            </svg>
        </div>
    )
}

export default SideMenuArrow