import { createAsyncThunk } from '@reduxjs/toolkit';
import Medusa from './api';


export const authService = createAsyncThunk('customer/login', async (customerDetails) => {
    return await Medusa.admin.login(customerDetails)
})

export const logout = async () => {
    return await Medusa.admin.logout();
}

export const ProductListService = async () => {
    return await Medusa.product.list();
}

export const BrandList = async () => {
    return await Medusa.brand.listMod();
}

export const AllBrandList = async () => {
    return await Medusa.brand.list();
}

export const styleLocatorListService = async () => {
    return await Medusa.locator.styleList();
}

export const BrandListRepeats = async () => {
    return await Medusa.brand.listAll();
}

export const CreateShopifyBrandService = async (payload) => {
    return await Medusa.brand.create(payload);
}

export const UpdateShopifyBrandService = async (payload) => {
    return await Medusa.brand.update(payload);
}

export const DeleteShopifyBrandService = async (id) => {
    return await Medusa.brand.delete(id);
}

export const syncVariantService = async (payload) => {
    return await Medusa.product.syncVariant(payload);
}

export const updateWebhookService = async (payload) => {
    return await Medusa.webhook.updateWebhook(payload);
}

//locator 
export const CreateLocatorService = async (payload) => {
    return await Medusa.locator.create(payload);
}
export const LocatorListService = async () => {
    return await Medusa.locator.list();
}
export const DeleteLocatorService = async (id) => {
    return await Medusa.locator.delete(id);
}
export const updateLocatorUpdateStatus = async (payload) => {
    return await Medusa.locator.updateStatus(payload);
}

export const locatorOnStore = async () => {
    return await Medusa.company.list();
}

export const listLocatorOnStore = async ({ queryKey }) => {
    const [_, storeName] = queryKey
    return await Medusa.locator.storeName(storeName);
}

export const storeCustomCss = async ({ queryKey }) => {
    const [_, storeName] = queryKey
    return await Medusa.locator.customCss(storeName);
}

export const updateLocatorService = async (payload) => {
    return await Medusa.locator.update(payload);
}
export const updateLocatorStyleService = async (payload) => {
    return await Medusa.locator.updateStyle(payload);
}
//error handler
export const ErrorListService = async () => {
    return await Medusa.error.list();
}
export const ErrorReloadService = async (paylaod) => {
    return await Medusa.error.reload(paylaod);
}
//order handler
export const orderSyncService = async (payload) => {
    return await Medusa.order.sync(payload);
}
//upload CSV
export const uploadCSVService = async (payload) => {
    return await Medusa.locator.uploadCsv(payload);
}

//login auth OTP
export const AuthOtpService = async (payload) => {
    return await Medusa.admin.authOTP(payload);
}

//get users
export const GetUsersService = async () => {
    return await Medusa.users.list();
}

//delete users soft
export const DeleteUsersService = async (id) => {
    return await Medusa.users.delete(id);
}

//reset token user
export const ResetTokenService = async (id) => {
    return await Medusa.users.resetToken(id);
}

//reset password service
export const ResetPasswordService = async (id) => {
    return await Medusa.users.resetPassword(id);
}

//get geolocation file status
export const GetGeoLocationFileStatusService = async () => {
    return await Medusa.locator.listStatus();
}

//get token request service 
export const GetTokenRequestService = async () => {
    return await Medusa.users.tokenRequest();
}




