import React, { useEffect } from 'react'
import LayoutMain from '../components/LayoutMain'
import { useMutation, useQuery } from 'react-query'
import { BrandListRepeats, CreateLocatorService, GetGeoLocationFileStatusService, LocatorListService, uploadCSVService } from '../services/service'
import spinner from './../assets/images/spinner.gif'
import { locatorSchema } from '../utils/formSchema'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from "@hookform/resolvers/yup";
import Select from 'react-select';
import { useState } from 'react';
import { smartySearch } from '../smartyServices/service'
import { useDispatch } from 'react-redux'
import { openSnack } from '../ConfigureStore/snackbarData';
import SelectSearch from '../components/SelectSearch'
import Button from '../components/Button'
import { loadToaster, loadingDecider } from '../ConfigureStore/globalLoader'
import CustomModal from '../components/CustomModal'
import { Dialog } from '@headlessui/react';
import FileUploaderCustom from '../components/FileUploaderCustom'
import Icon from '../components/Icon'
import DataTableCustom from '../components/DataTableCustom';
import { ArrowPathIcon, CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/outline'
import { convertDateTime } from '../utils/filters'



const Brands = () => {
    const dispatch = useDispatch();
    const { data: locatorData, isLoading: locatorDataLoading, refetch: locatorRefetch } = useQuery(['locatorList'], LocatorListService)
    const { data: brands, isLoading: brandsLoading, refetch: brandsRefetch } = useQuery(['brandAllData'], BrandListRepeats)
    const { data: geolocationFileStatusData, isLoading: geolocationFileStatusLoading, refetch: geolocationFileStatusRefetch } = useQuery(['geoFileStatus'], GetGeoLocationFileStatusService)
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);
    const [brandSelected, setBrandSelected] = useState([])
    const [brandData, setBrandData] = useState([]);
    const [addressQuery, setAddressQuery] = useState('')
    const [brandQuery, setBrandQuery] = useState('')
    const [timer, setTimer] = useState(null)
    const [addressSelected, setAddressSelected] = useState([])
    const [addressOption, setAddressOption] = useState([])
    const [showCsvModal, setShowCsvModal] = useState(false)
    const [showFileStatusModal, setShowFileStatusModal] = useState(false)
    const [file, setFile] = useState(null);
    const { mutate: CreateLocatorServiceMutate, isLoading: CreateLocatorServiceLoading } = useMutation(CreateLocatorService)
    const { mutate: uploadCsvServiceMutate, isLoading: uploadCsvServiceLoading } = useMutation(uploadCSVService)
    const {
        register,
        reset,
        handleSubmit,
        getValues,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(locatorSchema)
    })
    useEffect(() => {
        if (errors?.brandSelect) {
            dispatch(loadToaster({
                message: errors?.brandSelect?.name?.message,
                type: 'error',
                transition: 'bounce'
            }));
        }
        if (errors?.address1) {
            dispatch(loadToaster({
                message: errors?.address1?.message,
                type: 'error',
                transition: 'bounce'
            }));
        }
        if (errors?.statecode) {
            dispatch(loadToaster({
                message: errors?.statecode?.message,
                type: 'error',
                transition: 'bounce'
            }));
        }
        if (errors?.city) {
            dispatch(loadToaster({
                message: errors?.city?.message,
                type: 'error',
                transition: 'bounce'
            }));
        }
        if (errors?.zipcode) {
            dispatch(loadToaster({
                message: errors?.zipcode?.message,
                type: 'error',
                transition: 'bounce'
            }));
        }
        if (errors?.address2) {
            dispatch(loadToaster({
                message: errors?.address2?.message,
                type: 'error',
                transition: 'bounce'
            }));
        }
        if (errors?.companySelect) {
            dispatch(loadToaster({
                message: errors?.companySelect?.message,
                type: 'error',
                transition: 'bounce'
            }));
        }
    }, [errors])

    const handleCSVChange = (e) => {
        setFile(e);
    };

    const uploadGeolocatorFile = (e) => {
        e.preventDefault();
        if (!file) {
            dispatch(loadToaster({
                message: 'Please upload file',
                type: 'error',
                transition: 'bounce'
            }));
            return;
        }
        const payload = {
            file: file
        }
        uploadCsvServiceMutate(payload, uploadCSVFileFunctions)

    }
    const uploadCSVFileFunctions = {
        onSuccess: ((res) => {
            setFile(null);
            setShowCsvModal(false)
            dispatch(loadToaster({
                message: "Successfully uploaded",
                type: 'success',
                transition: 'bounce'
            }));
            locatorRefetch();
        }),
        onError: ((err) => {
            dispatch(loadToaster({
                message: 'Something went wrong',
                type: 'error',
                transition: 'bounce'
            }));
        })
    }
    const handleBrandShopify = (data) => {
        const payload = {
            brandId: data?.brandSelect?.id,
            brandLabel: data?.brandSelect?.name,
            city: data?.city,
            state: data?.statecode,
            streetline: data?.address1,
            streetline2: data?.address2,
            zipcode: data?.zipcode,
            companyName: data?.companySelect,
        }
        const resp = CreateLocatorServiceMutate(payload, createBrandShopifyFunctions)
    }


    const createBrandShopifyFunctions = {
        onSuccess: ((res) => {
            dispatch(loadToaster({
                message: "Successfully created",
                type: 'success',
                transition: 'bounce'
            }));
            brandsRefetch();
            locatorRefetch();
            reset()
        }),
        onError: ((err) => {
            dispatch(loadToaster({
                message: 'Something went wrong',
                type: 'error',
                transition: 'bounce'
            }));
        })
    }

    useEffect(() => {
        if (brands?.data?.length > 0) {
            const brandData = brands?.data?.map((item, key) => ({
                id: item?.id,
                name: item?.name
            }))
            setBrandData(brandData)
        }
    }, [brands])
    useEffect(() => {
        clearTimeout(timer)
        const fetchData = async () => {
            if (addressQuery) {
                const payload = {
                    keyword: addressQuery,
                }
                let response = await smartySearch(payload);
                response = response?.data?.suggestions?.map((item) => {
                    return {
                        id: item,
                        name: item?.street_line + ", " + item?.city + ", " + item?.state + ", " + item?.zipcode
                    }
                })
                setAddressOption(response)
            }
        }
        const newTimer = setTimeout(() => {
            fetchData();
        }, 700)
        setTimer(newTimer)
    }, [addressQuery])

    useEffect(() => {

        const filteredTempColumns = (
            <tr>
                <th>
                    <div className="flex items-center gap-4">
                        Filename
                        <Icon home="ArrowDown" className="cursor-pointer" />
                    </div>
                </th>
                <th>
                    <div className="flex items-center gap-4">
                        Percentage
                        <Icon home="ArrowDown" className="cursor-pointer" />
                    </div>
                </th>
                <th>
                    <div className="flex items-center gap-4">
                        Created At
                        <Icon home="ArrowDown" className="cursor-pointer" />
                    </div>
                </th>
                <th>
                    <div className="flex items-center gap-4">
                        Status
                        <Icon home="ArrowDown" className="cursor-pointer" />
                    </div>
                </th>

            </tr>
        )
        const filteredTempRows = geolocationFileStatusData?.data?.map((row, key) => (
            <tr key={key}>
                <td className="text-primary-500 font-normal overflow-hidden">
                    {row?.file_name}
                </td>
                <td className="text-primary-500 font-normal overflow-hidden">
                    {row?.percentage + "%"}
                </td>
                <td className="text-primary-500 font-normal overflow-hidden">
                    {convertDateTime(row?.created_at)}
                </td>
                <td className="text-primary-500 font-normal overflow-hidden">
                    {row?.status === 'In progress' ?
                        <ArrowPathIcon className="w-5 text-primary-500 cursor-pointer mr-2" /> : row?.status === 'Completed' ?
                            <CheckCircleIcon className="w-5 text-success-500 cursor-pointer mr-2" /> : row?.status === 'File format is incorrect' ?
                                <XCircleIcon className="w-5 text-error-500 cursor-pointer mr-2" /> : ""}
                </td>
            </tr >
        ))

        setColumns(filteredTempColumns);
        setRows(filteredTempRows);

    }, [geolocationFileStatusData])

    useEffect(() => {
        const payload = {
            ...getValues(),
            brandSelect: brandSelected,
        }
        reset(payload);
    }, [brandSelected])

    useEffect(() => {
        const payload = {
            ...getValues(),
            brandSelect: brandSelected,
            address1: addressSelected?.id?.street_line,
            city: addressSelected?.id?.city,
            zipcode: addressSelected?.id?.zipcode,
            statecode: addressSelected?.id?.state,
        }
        reset(payload);
    }, [addressSelected])

    const overAllLoading = brandsLoading || locatorDataLoading || CreateLocatorServiceLoading || uploadCsvServiceLoading || geolocationFileStatusLoading;
    useEffect(() => {
        if (overAllLoading) {
            dispatch(loadingDecider(true))
        }
        else {
            dispatch(loadingDecider(false))
        }
    }, [overAllLoading])
    return (
        <LayoutMain>
            <CustomModal
                open={showCsvModal}
                closeModal={() => setShowCsvModal(false)}
                modalComponent={
                    <>
                        <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                            <Dialog.Title
                                as="h3"
                                className="text-lg font-medium leading-6 text-gray-900"
                            >
                                Locator CSV Upload
                            </Dialog.Title>
                            <Dialog.Description className="text-sm text-gray-500 mt-4">
                                Upload a CSV file <br />
                            </Dialog.Description>
                            <div >
                                <FileUploaderCustom
                                    name='file'
                                    handleChange={handleCSVChange}
                                    file={file}
                                />
                            </div>
                            <div className='flex justify-end mt-4'>
                                <Button type="submit" className="btn btn-primary" title='Upload' functionality={uploadGeolocatorFile} />
                            </div>
                        </Dialog.Panel>
                    </>
                }
            />
            <CustomModal
                open={showFileStatusModal}
                closeModal={() => setShowFileStatusModal(false)}
                modalComponent={
                    <>
                        <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                            <Dialog.Title
                                as="h3"
                                className="text-lg font-medium leading-6 text-gray-900"
                            >
                                Uploaded files status
                            </Dialog.Title>
                            <DataTableCustom
                                rows={rows}
                                columns={columns}
                            />
                        </Dialog.Panel>
                    </>
                }
            />
            <div className="px-8 py-4">
                <div className="flex items-center justify-between">
                    <h3>
                        ADD LOCATOR ADDRESS
                    </h3>
                </div>
            </div >
            <div className="px-8 ">
                <div className="border-b flex gap-4"></div>
                <div className="box mt-4">
                    <div className="flex items-center justify-end">
                        <div className='mr-2 cursor-pointer' onClick={() => { setShowFileStatusModal(true) }}>
                            <Icon name="filestatus" />
                        </div>
                        <Button type="" className="btn btn-primary" title='Import CSV' functionality={() => { setShowCsvModal(true) }} />
                    </div>
                </div>
                <div className="mt-4 border border-primary-200 rounded-lg shadow p-5 w-1/2">

                    <form onSubmit={handleSubmit(handleBrandShopify)} className="space-y-4 mt-2">

                        <div>
                            <label for="brand_name">Stores</label>
                            <SelectSearch
                                listItems={brandData}
                                selected={brandSelected}
                                setSelected={setBrandSelected}
                                placeholder={'Select stores'}
                                query={brandQuery}
                                setQuery={setBrandQuery}
                                fullList={false}
                            />
                        </div>
                        <div>
                            <label for="brand_name">Company name</label>
                            <input   {...register('companySelect')} type="text" className="w-full my-1 placeholder:font-medium" placeholder="Enter company name" />
                        </div>
                        <div>
                            <label for="brand_name">Address autocomplete</label>
                            <SelectSearch
                                listItems={addressOption}
                                selected={addressSelected}
                                setSelected={setAddressSelected}
                                placeholder={'Enter address'}
                                query={addressQuery}
                                setQuery={setAddressQuery}
                                fullList={true}
                            />
                        </div>
                        <div>
                            <label for="brand_name">Address 1</label>
                            <input   {...register('address1')} type="text" className="w-full my-1 placeholder:font-medium" placeholder="Enter address 1" />
                        </div>
                        <div className='grid grid-cols-3 gap-4'>
                            <div >
                                <label for="brand_name">State code</label>
                                <input   {...register('statecode')} type="text" className="w-full my-1 placeholder:font-medium" placeholder="Enter state code" />
                            </div>
                            <div>
                                <label for="brand_name">City</label>
                                <input   {...register('city')} type="text" className="w-full my-1 placeholder:font-medium" placeholder="Enter city" />
                            </div>
                            <div >
                                <label for="brand_name">Postal code</label>
                                <input   {...register('zipcode')} type="text" className="w-full my-1 placeholder:font-medium" placeholder="Enter postal code" />
                            </div>
                        </div>
                        <div>
                            <label for="brand_name">Address 2</label>
                            <input   {...register('address2')} type="text" className="w-full my-1 placeholder:font-medium" placeholder="Enter Address 2" />
                        </div>
                        <div className='flex'>
                            <Button type="submit" className="btn btn-primary" title='Submit' />
                        </div>

                    </form>
                </div>
            </div>

        </LayoutMain >
    )
}

export default Brands