import React, { useState } from 'react'
import { logout } from '../services/service';
import { useMutation } from 'react-query';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { headerMenus } from '../utils/constant';
import Icon from './Icon';
import { Combobox, Menu, Transition } from '@headlessui/react'
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline';
import './../styles/header.scss';



const Header = (props) => {
  const [query, setQuery] = useState('');
  const navigate = useNavigate();
  const { mutate: logoutMutate, loading: logoutLoading } = useMutation(logout);
  const logoutHandle = () => {
    logoutMutate(
      {},
      {
        onSuccess: (data) => {
          navigate('/login')
        },
        onError: (err) => {
          console.log(err);
        },
      }
    );
  };
  return (
    <>
      <header>
        <Combobox>
          <div class="relative">
            <div class="relative">

            </div>
          </div>
        </Combobox>
        <div className="flex items-center gap-6">
          <Menu as="div" className="relative inline-block text-left z-10">
            <Menu.Button>
              <div className="flex items-center gap-2 p-3 border-primary-300 cursor-pointer hover:bg-primary-50" onClick={() => {
                logoutHandle()
              }}>
                <Icon home="Logout" />
              </div>
            </Menu.Button>
          </Menu>
        </div >
      </header>
      <div class="border-b-[0.5px] border-b-primary-200"></div>
    </>

  )
}

export default Header