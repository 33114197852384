/*global google*/
import React, { use, useEffect, useRef, useState } from 'react';
import './../styles/storeLocator.scss';
import SvgIcon from '../components/SvgIcon';
import red_pin from './../assets/red_pin.png';
import orange_pin from './../assets/orange_pin.png';

import {
    GoogleMap,
    Autocomplete,
    DirectionsRenderer,
    useJsApiLoader,
    MarkerClusterer,
    Marker,
} from '@react-google-maps/api';
import CustomMarker from '../components/CustomMarker';
import { useParams } from 'react-router-dom';
import { GetTokenRequestService, listLocatorOnStore, storeCustomCss } from '../services/service';
import { useQuery } from 'react-query';
import { storeLocatorCss } from '../utils/constant';


const containerStyle = {
    width: '100%',
    height: '100%',
};

const center = {
    lat: 40.7127753,
    lng: -74.0059728,
};

const BrandLocator = (props) => {
    const { brandName } = useParams();
    const [googleAPIToken] = useState('AIzaSyBkQAGlKDqFCKqxVG6U_Nzp5XhDl2nXiuw');
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: googleAPIToken,
        libraries: ['places'],
    });
    const [dynamicCSS, setDynamicCSS] = useState({});
    const { data: storeMarkersData, isLoading: storeMarkersLoading, refetch: storeMarkersRefetch } = useQuery(['storeMarkers', brandName], listLocatorOnStore)
    const { data: storeCustomCssData, isLoading: storeCustomCssLoading, refetch: storeCustomCssRefetch } = useQuery(['storeCustomCss', brandName], storeCustomCss)
    const myRef = useRef(null);
    const executeScroll = () => myRef.current.scrollIntoView();
    const [map, setMap] = useState(/** @type google.maps.Map*/ null);
    const [openInfo, setOpenInfo] = useState(null);
    const [markers, setMarkers] = useState([]);
    const [bound, setBound] = useState(null);
    const [currentPos, setCurrentPos] = useState(null);
    const [directionsResponse, setDirectionsResponse] = useState(null);
    const [isInputFilled, setIsInputFilled] = useState(false);
    //custom marker 
    const [selectedPlace, setSelectedPlace] = useState({});
    const [showingInfoWindow, setShowingInfoWindow] = useState(false);
    const [activeMarker, setActiveMarker] = useState({});
    //custom Css 
    const [customCss, setCustomCss] = useState({});
    const onMarkerClick = (props, marker) => {
        setActiveMarker(marker);
        setSelectedPlace(props);
        setShowingInfoWindow(true);
        scrollToId(marker?.properties?.storeFullAddress);

    }
    const scrollToId = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    };
    const onLocatorListClick = (props, marker) => {
        setActiveMarker(marker);
        setSelectedPlace(props);
        setShowingInfoWindow(true);
    }
    const onMapClick = () => {
        if (showingInfoWindow) {
            setShowingInfoWindow(false);
            setActiveMarker(null);
        }
    };

    const currentLocationMove = (map) => {
        navigator?.geolocation.getCurrentPosition(({ coords: { latitude: lat, longitude: lng } }) => {
            const pos = { lat, lng };
            setCurrentPos(pos);
            map.panTo(pos);
        });
    };
    useEffect(() => {
        const markers = storeMarkersData;
        let currentMarker = [];
        if (markers) {
            markers?.data?.map((item, key) => {
                let temp = {
                    "geometry": {
                        "type": "Point",
                        "coordinates": {
                            "lat": item?.lat,
                            "lng": item?.lng
                        }
                    },
                    "type": "Feature",
                    "properties": {
                        "store": (item?.company_name ? (item?.company_name?.replace(/ *\([^)]*\) */g, "")) : ("")),
                        "storeFullAddress": (item?.address2 !== null ? item?.address2 : "") + (item?.address1 !== null ? item?.address1 : "") + " " + item?.city + " " + item?.state + " " + item?.zipcode
                    }
                }
                currentMarker.push(temp);
            });
        }
        setMarkers(currentMarker);
    }, [storeMarkersData]);
    const payloadMaker = (Css) => {
        let customPayload = {
            mapId: Css?.mapId,
            header_background_color: {
                "background-color": Css?.header_section['background-color'],
            },
            header_text_color: {
                "color": Css?.header_section['text-color'],
            },
            header_border_color: {
                "border-color": Css?.header_section['border-color'],
            },
            left_section_background_color: {
                "background-color": Css?.left_section['background-color'],
            },
            left_section_text_color: {
                "color": Css?.left_section['text-color']
            }
        }
        return customPayload;
    }
    useEffect(() => {
        if (storeCustomCssData?.data?.length > 0) {
            let Css = JSON.parse(storeCustomCssData?.data[0]?.custom_css);
            setCustomCss(payloadMaker(Css));
        }
        else {
            setCustomCss(payloadMaker(storeLocatorCss));
        }
    }, [storeCustomCssData]);

    useEffect(() => {
        if (map) {
            currentLocationMove(map);
        }
    }, [map]);





    const onAddressSearch = async () => {
        const isSearchPresent = currentLocation !== null && currentLocation.current.value !== '';
        if (isSearchPresent) {
            let geolocation = new google.maps.Geocoder();
            try {
                geolocation = await geolocation.geocode({ address: currentLocation.current.value });
                const pos = {
                    lat: geolocation?.results[0]?.geometry?.location.lat(),
                    lng: geolocation?.results[0]?.geometry?.location.lng(),
                };
                setCurrentPos(pos);
                map.panTo(pos);
            } catch (e) { }
        }
    };
    const [distance, setDistance] = useState('');
    const [duration, setDuration] = useState('');

    const currentLocation = useRef(null);

    const calculateRoute = (originAddress) => {
        try {
            if (originAddress === undefined && currentPos === null) {
                return;
            }
            let directionsUrl = `https://www.google.com/maps/dir/?api=1${currentPos !== null ? `&origin=${currentPos?.lat},${currentPos?.lng}` : ''}&destination= ${originAddress?.lat},${originAddress?.lng}`;
            window.open(directionsUrl, '_blank');
            // eslint-disable-next-line no-undef
            // var directionsService = new google.maps.DirectionsService();
            // var directionsDisplay = new google.maps.DirectionsRenderer();
            // directionsService.route(
            //     {
            //         origin: originAddress,
            //         destination: currentPos,
            //         avoidTolls: true,
            //         avoidHighways: false,
            //         // eslint-disable-next-line no-undef
            //         travelMode: google.maps.TravelMode.DRIVING,
            //     },
            //     function (response, status) {
            //         if (status == google.maps.DirectionsStatus.OK) {
            //             directionsDisplay.setDirections(response);
            //             //set properties
            //             setDirectionsResponse(response);
            //             setDistance(response.routes[0].legs[0].distance.text);
            //             setDuration(response.routes[0].legs[0].duration.text);
            //         } else {
            //             window.alert('Directions request failed due to ' + status);
            //         }
            //     }
            // );
        } catch (e) {
            console.log(e);
        }
    };
    const calculator = (markers, numStyles) => {
        var index = 0;
        var count = markers.length;
        var dv = count;
        while (dv !== 0) {
            dv = parseInt(dv / 10, 10);
            index++;
        }
        index = Math.min(index, numStyles);
        return {
            text: count,
            index: index,
            title: 's',
        };
    };
    function clearRoute() {
        document.querySelector('.search-input input').value = '';
        setIsInputFilled(false);
        setDirectionsResponse(null);
        setCurrentPos(null);
        setDistance('');
        setDuration('');
    }

    const handleSearchChange = (e) => {
        setIsInputFilled(!!e.target.value);
    };
    const overallLoader = isLoaded || !storeMarkersLoading || !storeCustomCssLoading;
    return (
        <div className="store-locator-page" style={{ ...dynamicCSS }}>
            {overallLoader ? (
                <>
                    <div className='locator-form' style={customCss?.header_background_color}>
                        <p>{props.heading}</p>
                        <div className="form">
                            <Autocomplete className="auto-complete" options={{ componentRestrictions: { country: 'us' } }}>
                                <div className="search-input" style={customCss?.header_border_color}>
                                    <input
                                        type="text"
                                        placeholder="ZIPCODE"
                                        ref={currentLocation}
                                        onChange={(e) => {
                                            handleSearchChange(e);
                                        }}
                                        style={customCss?.header_text_color}
                                    />
                                    {!isInputFilled && (
                                        <button
                                            onClick={() => {
                                                currentLocationMove(map);
                                            }}
                                        >
                                            <SvgIcon getName="locate" />
                                        </button>
                                    )}
                                    {isInputFilled && (
                                        <button className="close-btn" onClick={() => clearRoute()}>
                                            <SvgIcon getName="close" />
                                        </button>
                                    )}
                                </div>
                            </Autocomplete>
                            <button className="bordered-btn custom-button" style={{ ...customCss?.header_text_color, ...customCss?.header_border_color }} onClick={onAddressSearch}>
                                Search
                            </button>
                        </div>
                    </div>
                    <div className="locator-results" style={customCss?.left_section_background_color}>
                        <ul className="stores-list">
                            <li className="list-header pl-0" style={customCss?.left_section_background_color}>
                                {' '}
                                <p style={customCss?.left_section_text_color}>Number of Stores: {markers.length}</p>
                            </li>
                            {markers.length > 0 ? (
                                markers.map((item, key) => (
                                    <li
                                        animation={google.maps.Animation.DROP}
                                        onClick={() => {
                                            map.setZoom(11);
                                            map.panTo(item?.geometry?.coordinates);
                                            setOpenInfo(item?.properties?.storeFullAddress);
                                            onLocatorListClick(item, item)
                                        }}
                                        id={item?.properties?.storeFullAddress}
                                        className="stores-items cursor-pointer py-3"
                                        key={key}>
                                        <p style={customCss?.left_section_text_color}>
                                            {item?.properties?.store}
                                        </p>
                                        <span style={customCss?.left_section_text_color}>{item?.properties?.storeFullAddress}</span>
                                        <button style={customCss?.left_section_text_color} onClick={() => calculateRoute(item?.geometry?.coordinates)}>Directions</button>
                                    </li>
                                ))
                            ) : (
                                <>
                                    <div style={customCss?.left_section_text_color}>No Store Available</div>
                                </>
                            )}
                        </ul>

                        <div id="map" className="store-map">
                            <GoogleMap
                                center={center}
                                zoom={11}
                                options={{
                                    zoomControl: true,
                                    streetViewControl: false,
                                    mapTypeControl: false,
                                    fullscreenControl: false,
                                    mapId: customCss?.mapId,
                                    keyboardShortcuts: false,
                                }}
                                onLoad={(map) => {
                                    setMap(map);
                                }}
                                onClick={onMapClick}
                                // onBoundsChanged={handleChangeLocation}
                                mapContainerStyle={containerStyle}
                            >
                                <MarkerClusterer minimumClusterSize={2} calculator={calculator}>
                                    {(clusterer) =>
                                        markers?.map((item, key) => {
                                            return (
                                                <CustomMarker
                                                    openInfo={openInfo}
                                                    clusterer={clusterer}
                                                    activeMarker={activeMarker}
                                                    showingInfoWindow={showingInfoWindow}
                                                    key={key}
                                                    onMarkerClick={onMarkerClick}
                                                    coordinates={item?.geometry?.coordinates}
                                                    storeName={item?.properties?.store}
                                                    address={item?.properties?.storeFullAddress}
                                                    map={map}
                                                    marker={item}
                                                    icon={{ scaledSize: new google.maps.Size(24, 40), url: red_pin }}
                                                    calculateRoute={calculateRoute}
                                                />
                                            )
                                        })
                                    }
                                </MarkerClusterer>
                                {currentPos !== null && (
                                    <Marker
                                        position={currentPos}
                                        animation={google.maps.Animation.DROP}
                                        draggable={true}
                                        onDragEnd={(data) => {
                                            const cordinates = {
                                                lat: data?.latLng?.lat(),
                                                lng: data?.latLng?.lng(),
                                            }
                                            setCurrentPos(cordinates);
                                            map.panTo(cordinates);
                                        }}
                                        icon={{ scaledSize: new google.maps.Size(23, 40), url: orange_pin }}
                                    />
                                )}

                                {directionsResponse && <DirectionsRenderer directions={directionsResponse} />}
                            </GoogleMap>
                        </div>
                    </div>
                </>
            ) : (
                <></>
            )}
        </div>
    );
};

export default BrandLocator;
