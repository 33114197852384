export const headerMenus = [
    {
        name: 'Products',
        href: '/products',
        icon: 'Products',
        openable: '',
        submenu: null
    },
    {
        name: 'Stores',
        href: '/brands/list',
        openable: 'menu-open',
        icon: 'Orders',
        submenu: [
            {
                heading: 'Stores',
                name: 'Add Credentials',
                href: '/brands',
                color: 'text-primary',
                icon: 'Orders',
                menuPos: 28,//add 50 to the previous menuPos
                lineHeight: 15
            }
            // {
            //     heading: 'Stores',
            //     name: 'List',
            //     href: '/brands/list',
            //     color: 'text-success',
            //     icon: 'Orders',
            //     menuPos: 28
            // }
        ]
    },
    {
        name: 'Geo Locator',
        href: '/locators/list',
        openable: 'menu-open',
        icon: 'StockNotification',
        submenu: [
            {
                heading: 'Geo Locator',
                name: 'Add',
                href: '/locators',
                color: 'text-primary',
                icon: 'Orders',
                menuPos: 80, //add 50 to the previous menuPos
                lineHeight: 8
            },
            {
                heading: 'Geo Locator',
                name: 'Style',
                href: '/locators/style',
                color: 'text-warning',
                icon: 'Orders',
                menuPos: 24,
                lineHeight: 8

            }
            // {
            //     heading: 'Geo Locator',
            //     name: 'List',
            //     href: '/locators/list',
            //     color: 'text-success',
            //     icon: 'Orders',
            //     menuPos: 80 //add 50 to the previous menuPos
            // },

        ]
    },
    {
        name: 'Users',
        href: '/users/list',
        openable: 'menu-open',
        icon: 'UserEdit',
        submenu: null
    },
    {
        name: 'Failed Task',
        href: '/errors/list',
        openable: 'menu-open',
        icon: 'PaymentTerms',
        submenu: null
    },

]
export const storeLocatorCss = {
    mapId: '905e8fb663c81e3c',
    header_section: {
        "background-color": "#000000",
        "border-color": "#FFFFFF",
        "text-color": "#FFFFFF",
    },
    left_section: {
        "background-color": "#FFFFFF",
        "text-color": "#000000",
    }
}