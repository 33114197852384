import React, { useEffect, useState } from 'react'
import LayoutLogin from '../components/LayoutLogin';
import { Link, useNavigate } from 'react-router-dom';
import spinner from './../assets/images/spinner.gif'
import MedusaLogo from './../assets/medusa.png'
import { authService } from '../services/service';
import { useDispatch, useSelector } from 'react-redux';
import { loginSchema } from "./../utils/formSchema";
import { useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import Cookies from 'js-cookie';
import Button from '../components/Button';
import {
  EyeIcon,
  EyeSlashIcon
} from "@heroicons/react/24/outline";
import { loadToaster, loadingDecider } from '../ConfigureStore/globalLoader';


const Login = (props) => {
  const [passwordType, setPasswordType] = useState(true)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({ resolver: yupResolver(loginSchema) })

  if (errors) {
    if (errors?.email) {
      dispatch(loadToaster({
        message: errors?.email?.message,
        type: 'error',
        transition: 'bounce'
      }))
    }
    if (errors?.password) {
      dispatch(loadToaster({
        message: errors?.password?.message,
        type: 'error',
        transition: 'bounce'
      }))
    }
  }
  const onSubmitLogin = (data) => {
    dispatch(loadingDecider(true));
    dispatch(authService(data)).then((customer) => {
      if (customer?.error?.message) {
        dispatch(loadToaster({
          message: "Invalid Credentials",
          type: 'error',
          transition: 'bounce'
        }));
      }
      if (customer?.payload?.data) {
        const id = customer?.payload?.data?.id;
        // dispatch(loadToaster({
        //   message: "Login Successfully",
        //   type: 'success',
        //   transition: 'bounce'
        // }));
        navigate(`/auth/${id}`)
      }
    }).finally(() => {
      dispatch(loadingDecider(false));
    })
  }
  // useEffect(() => {
  //   const accessToken = Cookies.get('accessToken')
  //   if (accessToken) {
  //     navigate('/products')
  //   }
  // }, [])

  return (
    <>
      <LayoutLogin>
        <div
          className="bg-gradient-to-bl from-primary-500 via-primary-200 to-primary-100 h-screen w-full flex items-center justify-center">
          <div className="bg-primary-50 p-5 shadow-md rounded-lg h-96 w-96 space-y-10">
            <div className="flex items-center justify-center gap-2">
              <img src={MedusaLogo} alt="" className="w-12 h-12" />
              <h3 className="text-primary-700">Medusa App</h3>
            </div>
            <form method="post" onSubmit={handleSubmit(onSubmitLogin)} className="space-y-4">
              <div>
                <label for="email" className="font-light">E-Mail Address</label>
                <input className="w-full mt-1" {...register('email')} type="email" name="email" required autofocus autocomplete />
              </div>
              <div>
                <label for="password" className="font-light">Password</label>
                <div className="relative">
                  <input className="w-full mt-1" {...register('password')} type={passwordType ? 'password' : 'text'} name="password" required autofocus autocomplete />
                  {
                    passwordType ?
                      <EyeSlashIcon className="absolute right-2 top-3.5 h-5 w-5 text-gray-500 cursor-pointer " onClick={() => { setPasswordType(!passwordType) }} /> :
                      <EyeIcon className="absolute right-2 top-3.5 h-5 w-5 text-gray-500 cursor-pointer" onClick={() => { setPasswordType(!passwordType) }} />}

                </div>
              </div>
              <div className="flex items-center gap-2">
                <input type="checkbox" className="primary-checkbox" autofocus />
                <label for="remember_me" className="font-light">Remember Me</label>
              </div>
              <Button type="submit" className="btn btn-primary w-full" title="Sign In" />
            </form>
          </div>
        </div>
      </LayoutLogin >
    </>
  )
}

export default Login