import React from 'react'
import { useSelector } from 'react-redux';

const LayoutLogin = ({ children }) => {
  return (
    <div>
      {children}
    </div >
  )
}

export default LayoutLogin