import { InfoWindow, Marker } from '@react-google-maps/api';
import React, { useEffect, useState } from 'react';

const CustomMarker = (props) => {
  return (
    <Marker
      clusterer={props?.clusterer}
      position={props?.coordinates}
      onClick={() => props.onMarkerClick(props, props.marker)}
      icon={props.icon}
    >
      {props.activeMarker === props.marker && (
        <InfoWindow
          options={{
            pixelOffset: {
              width: 0,
              height: -3,
            },
          }}
          position={props?.coordinates}
          visible={props.showingInfoWindow}
        >
          <div className="text-black">
            <p className="mb-1">
              <strong>{props?.storeName}</strong>
            </p>
            <p className="mb-4">{props?.address}</p>
            <button onClick={() => props?.calculateRoute(props?.coordinates)} className="bordered-btn medium dark">
              directions
            </button>
          </div>
        </InfoWindow>
      )}


    </Marker >
  );
};

export default CustomMarker;
