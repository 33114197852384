import React, { useEffect, useState } from 'react';
import LayoutMain from '../components/LayoutMain';
import { useQuery } from 'react-query';
import { BrandList, DeleteShopifyBrandService, orderSyncService, updateWebhookService } from '../services/service';
import './../styles/brandsList.scss';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { loadToaster, loadingDecider } from '../ConfigureStore/globalLoader';
import { Dialog, Tab } from '@headlessui/react';
import DataTableCustom from '../components/DataTableCustom';
import Icon from '../components/Icon';
import { ArrowUpOnSquareIcon, PencilSquareIcon, TrashIcon, ArrowPathRoundedSquareIcon } from '@heroicons/react/24/outline';
import CustomModal from '../components/CustomModal';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { brandUpdateSchema } from '../utils/formSchema';
import Button from '../components/Button';
import Datepicker from "react-tailwindcss-datepicker";




const BrandsList = (props) => {
    const { data: brandsData, isLoading: brandsDataLoading, refetch: brandsDataRefetch } = useQuery(['BrandList'], BrandList)
    const { mutate: updateWebhookHandler, isLoading: updateWebhookLoading } = useMutation(updateWebhookService)
    const { mutate: orderSyncHandler, isLoading: orderSyncServiceLoading } = useMutation(orderSyncService)
    // const { mutate: updateBrandShopifyMutate, isLoading: updateBrandShopifyLoading } = useMutation(UpdateShopifyBrandService)
    const { mutate: deleteBrandShopifyMutate, isLoading: deleteBrandShopifyLoading } = useMutation(DeleteShopifyBrandService)
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);
    const dispatch = useDispatch();

    // const [showUpdate, setShowUpdate] = useState(false);
    const [showOrderSync, setShowOrderSync] = useState(false);
    const [showDelete, setShowDelete] = useState(false);

    const [dateValue, setDateValue] = useState({
        startDate: null,
        endDate: null
    });
    const handleValueChange = (newValue) => {
        setDateValue(newValue);
    }

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm({ resolver: yupResolver(brandUpdateSchema) })


    const [currentRow, setCurrentRow] = useState();



    const handleBrandDeleteShopify = (data) => {
        deleteBrandShopifyMutate(data, deleteBrandShopifyFunctions)
    }
    const deleteBrandShopifyFunctions = {
        onSuccess: ((res) => {
            brandsDataRefetch();
            dispatch(loadToaster({
                message: "Successfully deleted",
                type: 'success',
                transition: 'bounce'
            }))
        }),
        onError: ((err) => {
            dispatch(loadToaster({
                message: "something went wrong",
                type: 'error',
                transition: 'bounce'
            }))
        }),
        onSettled: (() => {
            setShowDelete(false);
        })
    }

    // const handleBrandUpdateShopify = (data) => {
    //     updateBrandShopifyMutate(data, updateBrandShopifyFunctions)
    // }
    // const updateBrandShopifyFunctions = {
    //     onSuccess: ((res) => {
    //         brandsDataRefetch();
    //         setShowUpdate(false);
    //         reset();
    //         dispatch(loadToaster({
    //             message: "successfully Updated",
    //             type: 'success',
    //             transition: 'bounce'
    //         }))
    //     }),
    //     onError: ((err) => {
    //         dispatch(loadToaster({
    //             message: "something went wrong",
    //             type: 'error',
    //             transition: 'bounce'
    //         }))
    //     })
    // }
    useEffect(() => {
        reset({ id: currentRow?.id })
    }, [currentRow]);


    const handleOnChangeDelete = (row) => {
        setCurrentRow(row)
        setShowDelete(true);
    }
    const handleOnChangeOrderSync = (row) => {
        setCurrentRow(row)
        setShowOrderSync(true);
    }
    // const handleOnChangeUpdate = (row) => {
    //     setCurrentRow(row)
    //     setShowUpdate(true);
    // }
    const handleUploadWebhook = (row) => {
        const payload = {
            slug: row.brand_name
        }
        updateWebhookHandler(payload, webhookUpdateFunctions)
    }

    const handleOrderSync = (row) => {
        if (dateValue.startDate === null && dateValue.endDate === null) {
            dispatch(loadToaster({
                message: "Please select date range",
                type: 'error',
                transition: 'bounce'
            }))
            return;
        }
        const payload = {
            slug: row.brand_name,
            startDate: dateValue.startDate,
            endDate: dateValue.endDate
        }
        orderSyncHandler(payload, orderSyncFunctions)
    }
    const orderSyncFunctions = {
        onSuccess: (res) => {
            if (res?.data?.message) {
                dispatch(loadToaster({
                    message: "successfully Synced",
                    type: 'success',
                    transition: 'bounce'
                }))
                brandsDataRefetch();
            }
            else {
                dispatch(loadToaster({
                    message: "something went wrong",
                    type: 'error',
                    transition: 'bounce'
                }))
            }

        },
        onError: (error) => {
            dispatch(loadToaster({
                message: "something went wrong",
                type: 'error',
                transition: 'bounce'
            }))
        },
        onSettled: (() => {
            setShowOrderSync(false);
        })
    }

    const webhookUpdateFunctions = {
        onSuccess: (res) => {
            if (res?.data?.message) {
                dispatch(loadToaster({
                    message: "successfully added",
                    type: 'success',
                    transition: 'bounce'
                }))
                brandsDataRefetch();
            }
            else {
                dispatch(loadToaster({
                    message: "something went wrong",
                    type: 'error',
                    transition: 'bounce'
                }))
            }
        },
        onError: (error) => {
            dispatch(loadToaster({
                message: "something went wrong",
                type: 'error',
                transition: 'bounce'
            }))
        }
    }
    useEffect(() => {
        console.log(brandsData)
        if (brandsData) {
            const filteredTempColumns = (
                <tr>
                    <th>
                        <div class="flex items-center gap-4">
                            Brands
                            <Icon home="ArrowDown" class="cursor-pointer" />
                        </div>
                    </th>
                    <th>
                        <div class="flex items-center gap-4">
                            Store Slugs
                            <Icon home="ArrowDown" class="cursor-pointer" />
                        </div>
                    </th>
                    <th>
                        <div class="flex items-center gap-4">
                            Webhook Count
                            <Icon home="ArrowDown" class="cursor-pointer" />
                        </div>
                    </th>
                    <th>
                        <div class="flex items-center gap-4">
                            Actions
                            <Icon home="ArrowDown" class="cursor-pointer" />
                        </div>
                    </th>
                </tr>
            )
            const filteredTempRows = brandsData?.data?.map((row, key) => (
                <tr key={key}>
                    <td class="text-primary-500 overflow-hidden">
                        {row.name}
                    </td>
                    <td class="text-primary-500  overflow-hidden">
                        {row.brand_name}
                    </td>
                    <td class="text-primary-500  overflow-hidden">
                        {((row.web_hook !== null ? row.web_hook : '[]').length > 0 ? (row.web_hook !== null ? row.web_hook : '[]').length : "No Webhook")}
                    </td>
                    <td class="text-primary-500 overflow-hidden flex mt-2">
                        {/* <PencilSquareIcon className="w-5 text-success-500 cursor-pointer" onClick={() => { handleOnChangeUpdate(row); }} /> */}
                        {/* <TrashIcon className="w-5 text-error-500 cursor-pointer mr-4" onClick={() => { handleOnChangeDelete(row); }} /> */}
                        <ArrowUpOnSquareIcon className="w-5 text-primary-500 cursor-pointer mr-4" onClick={() => { handleUploadWebhook(row); }} />
                        <ArrowPathRoundedSquareIcon className="w-5 text-success-500 cursor-pointer" onClick={() => { handleOnChangeOrderSync(row); }} />
                    </td>
                </tr>
            ))
            setColumns(filteredTempColumns);
            setRows(filteredTempRows);
        }
    }, [brandsData])
    const overallLoading = brandsDataLoading || orderSyncServiceLoading || updateWebhookLoading;
    useEffect(() => {
        if (overallLoading) {
            dispatch(loadingDecider(true));
        }
        else {
            dispatch(loadingDecider(false));
        }
    }, [overallLoading])

    return (
        <LayoutMain>
            {/* <CustomModal
                open={showUpdate}
                closeModal={() => setShowUpdate(false)}
                modalComponent={
                    <>
                        <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                            <Dialog.Title
                                as="h3"
                                className="text-lg font-medium leading-6 text-gray-900"
                            >
                                Update Brand Credentials
                            </Dialog.Title>

                            <div className="mt-4">
                                <div className="border-b flex gap-4"></div>
                                <form onSubmit={handleSubmit(handleBrandUpdateShopify)} className="space-y-4 mt-2">
                                    <div hidden>
                                        <label for="brand_name">Company Name</label>
                                        <input {...register('id')} type="text" className="w-full my-1 placeholder:font-medium" placeholder="Enter Brand Key" disabled />
                                    </div>
                                    <div>
                                        <label for="brand_name">Brand Name</label>
                                        <input {...register('brand_name')} type="text" className="w-full my-1 placeholder:font-medium" placeholder="Enter Brand Name" />
                                    </div>
                                    <div>
                                        <label for="brand_name">Token</label>
                                        <input {...register('token')} type="text" className="w-full my-1 placeholder:font-medium" placeholder="Enter Token" />
                                    </div>

                                    <Button type="submit" className="btn btn-primary" title='Submit' />
                                </form>

                            </div>
                        </Dialog.Panel>
                    </>
                }
            /> */}
            <CustomModal
                open={showOrderSync}
                closeModal={() => setShowOrderSync(false)}
                modalComponent={
                    <>
                        <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                            <Dialog.Title
                                as="h3"
                                className="text-lg font-medium leading-6 text-gray-900"
                            >
                                Are you sure want to sync orders?
                            </Dialog.Title>
                            <div className="relative mt-4">
                                {/* <div className="border-b flex gap-4 mb-3"></div> */}
                                <div className='flex justify-end'>
                                    <Button type="submit" className="btn btn-success mx-3" title='Sync' functionality={() => handleOrderSync(currentRow)} />
                                    <Button type="submit" className="btn btn-danger" title='Cancel' functionality={() => setShowOrderSync(false)} />
                                </div>

                            </div>
                        </Dialog.Panel>
                    </>
                }
            />
            <CustomModal
                open={showDelete}
                closeModal={() => setShowDelete(false)}
                modalComponent={
                    <>
                        <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                            <Dialog.Title
                                as="h3"
                                className="text-lg font-medium leading-6 text-gray-900"
                            >
                                Are you sure to delete?
                            </Dialog.Title>

                            <div className="mt-4">
                                <div className='flex justify-end'>
                                    <Button type="submit" className="btn btn-success mx-3" title='Yes' functionality={() => handleBrandDeleteShopify(currentRow?.brand_id)} />
                                    <Button type="submit" className="btn btn-danger" title='No' functionality={() => setShowDelete(false)} />
                                </div>

                            </div>
                        </Dialog.Panel>
                    </>
                }
            />
            <div className="heading-container">
                <div className="heading-main">
                    <h3>
                        Stores
                    </h3>
                </div>

            </div >
            <div className="px-8 ">
                <div className="border-b flex gap-4"></div>
                <Tab.Group>
                    <Tab.Panels>
                        <Tab.Panel>
                            <div className="box mt-4">
                                <div className="flex items-center justify-between">
                                    <div></div>
                                    <div className="action">
                                        <Datepicker
                                            inputClassName="w-80"
                                            placeholder="Select Dates"
                                            separator=" - "
                                            value={dateValue}
                                            onChange={handleValueChange}
                                            showShortcuts={true}
                                        />
                                    </div>
                                </div>
                            </div>
                            <DataTableCustom
                                rows={rows}
                                columns={columns}
                            />
                        </Tab.Panel>
                    </Tab.Panels>
                </Tab.Group>
            </div>
        </LayoutMain>
    )
}

export default BrandsList
