import React, { useEffect, useState } from 'react'
import LayoutMain from '../components/LayoutMain'
import { useMutation, useQuery } from 'react-query';
import { BrandList, ProductListService, syncVariantService } from '../services/service';
import './../styles/dashboard.scss';
import { useDispatch } from 'react-redux';
import { openSnack } from '../ConfigureStore/snackbarData';
import { Switch, Tab } from '@headlessui/react';
import { ArrowPathIcon, CheckCircleIcon } from '@heroicons/react/24/outline';
import Icon from '../components/Icon';
import SelectSearch from '../components/SelectSearch';
import SearchCustom from '../components/SearchCustom';
import DataTableCustom from '../components/DataTableCustom';
import { loadingDecider } from '../ConfigureStore/globalLoader';

const Dashboard = () => {
    const { mutate: syncVariant, isLoading: syncVariantLoading } = useMutation(syncVariantService);
    const { data: prod, isLoading: prodLoading, refetch: prodDataRefetch } = useQuery(['products'], ProductListService)
    const { data: brands, isLoading: brandsLoading, refetch: brandsRefetch } = useQuery(['brandsDataMod'], BrandList);

    const [filter, setFilter] = useState(false);
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);
    const [syncDecider, setSyncDecider] = useState(['sync']);
    const [search, setSearch] = useState('');
    const [storeList, setStoreList] = useState([]);
    const [brandList, setBrandList] = useState([]);
    const [storeSelected, setStoreSelected] = useState([]);
    const [brandSelected, setBrandSelected] = useState([]);
    const [storeQuery, setStoreQuery] = useState('');
    const [brandQuery, setBrandQuery] = useState('');
    const dispatch = useDispatch();

    useEffect(() => {
        if (prod) {
            const uniq = [...new Set(prod?.data.map((item, key) => item?.ActualBrand))].map((item, key) => {
                return (
                    { id: key + 1, name: item }
                )
            });
            setStoreList(uniq)
        }
    }, [prod])
    const handleSyncVariant = (row) => {
        const payload = {
            "shopify_id": row?.shopify_id,
            "brand_id": row?.brand_id,
            "sku": row?.sku,
            "inventory_item_id": row?.inventory_item_id,
            "shopify_title": row?.shopify_title,
        }
        syncVariant(payload, syncVariantFunctions)
    }
    const syncVariantFunctions = {
        onSuccess: ((res) => {
            if (res?.data?.message === 'success') {
                dispatch(openSnack({ message: "variant has been synced", severity: "success", open: true }))
            }
            if (res?.data?.message === 'not found') {
                dispatch(openSnack({ message: "variant isn\'t available in medusa distribution", severity: "error", open: true }))
            }
            prodDataRefetch();
        }),
        onError: ((err) => {
            console.log(err)
        })
    }
    useEffect(() => {
        if (brands?.data) {
            let tempBrandList = [];
            brands?.data?.map((item, key) => {
                tempBrandList.push({
                    id: key + 1,
                    name: item.name
                })
            })
            setBrandList(tempBrandList)
        }
    }, [brands])
    useEffect(() => {
        if (search === '' && storeSelected.length === 0 && syncDecider.length === 0 && brandSelected.length === 0) {
            const filteredTempColumns = (
                <tr>
                    <th>
                        <div class="flex items-center gap-4">
                            Shopify Title
                            <Icon home="ArrowDown" class="cursor-pointer" />
                        </div>
                    </th>
                    <th>
                        <div class="flex items-center gap-4">
                            Product Name
                            <Icon home="ArrowDown" class="cursor-pointer" />
                        </div>
                    </th>
                    <th>
                        <div class="flex items-center gap-4">
                            Variant Name
                            <Icon home="ArrowDown" class="cursor-pointer" />
                        </div>
                    </th>
                    <th>
                        <div class="flex items-center gap-4">
                            SKU
                            <Icon home="ArrowDown" class="cursor-pointer" />
                        </div>
                    </th>
                    <th>
                        <div class="flex items-center gap-4">
                            Brand Source
                            <Icon home="ArrowDown" class="cursor-pointer" />
                        </div>
                    </th>
                    <th>
                        <div class="flex items-center gap-4">
                            Store Source
                            <Icon home="ArrowDown" class="cursor-pointer" />
                        </div>
                    </th>
                    <th>
                        <div class="flex items-center gap-4">
                            Available Stock
                            <Icon home="ArrowDown" class="cursor-pointer" />
                        </div>
                    </th>
                    <th>
                        <div class="flex items-center gap-4">
                            Stock On Hand
                            <Icon home="ArrowDown" class="cursor-pointer" />
                        </div>
                    </th>
                    <th>
                        <div class="flex items-center gap-4">
                            Actions
                            <Icon home="ArrowDown" class="cursor-pointer" />
                        </div>
                    </th>
                </tr>
            )
            const filteredTempRows = prod?.data?.map((row, key) => (
                <tr key={key}>
                    <td class="text-primary-500  overflow-hidden">
                        {row.shopify_title}
                    </td>
                    <td class="text-primary-500  overflow-hidden">
                        {row.product_name}
                    </td>
                    <td class="text-primary-500  overflow-hidden">
                        {row.name}
                    </td>
                    <td class="text-primary-500  overflow-hidden">
                        {row.sku}
                    </td>
                    <td class="text-primary-500  overflow-hidden">
                        {row.ActualBrand}
                    </td>
                    <td class="text-primary-500  overflow-hidden">
                        {row.brand_name}
                    </td>
                    <td class="text-primary-500  overflow-hidden">
                        {row.available_stock}
                    </td>
                    <td>
                        {row.stock_on_hand}
                    </td>
                    <td>
                        {
                            row?.variant_id === 'N/A' ?
                                <>
                                    <ArrowPathIcon className="text-error-500 w-5 cursor-pointer" onClick={() => { handleSyncVariant(row) }} />
                                </>
                                :
                                <>
                                    <CheckCircleIcon className="text-success-500 w-5 cursor-pointer" />
                                </>
                        }
                    </td>
                </tr>
            ))
            setRows(filteredTempRows)
            setColumns(filteredTempColumns)
            return;
        }
        const value = search.toLowerCase();
        const filtered = prod?.data?.filter((item) => (
            item.brand_name.toLowerCase().includes(value) ||
            item.name.toLowerCase().includes(value) ||
            item.sku.toLowerCase().includes(value) ||
            item.product_name.toLowerCase().includes(value) ||
            item.ActualBrand.toLowerCase().includes(value)
        ))

        const filtered2 = filtered?.filter((item) => (syncDecider?.length === 0 ? true : syncDecider.includes('sync') ? item.variant_id !== 'N/A' ? true : false : false) || (syncDecider.includes('unsync') ? item.variant_id === 'N/A' ? true : false : false))
        const filtered3 = filtered2?.filter((item) => (storeSelected.length === 0 ? true : item.ActualBrand === storeSelected?.name))
        const filtered4 = filtered3?.filter((item) => (brandSelected.length === 0 ? true : item.brand_name === brandSelected?.name))
        const filteredTempColumns = (
            <tr>
                <th>
                    <div class="flex items-center gap-4">
                        Shopify Title
                        <Icon home="ArrowDown" class="cursor-pointer" />
                    </div>
                </th>
                <th>
                    <div class="flex items-center gap-4">
                        Product/Variant Name
                        <Icon home="ArrowDown" class="cursor-pointer" />
                    </div>
                </th>
                {/* <th>
                    <div class="flex items-center gap-4">
                        Variant Name
                        <Icon home="ArrowDown" class="cursor-pointer" />
                    </div>
                </th> */}
                <th>
                    <div class="flex items-center gap-4">
                        SKU
                        <Icon home="ArrowDown" class="cursor-pointer" />
                    </div>
                </th>
                <th>
                    <div class="flex items-center gap-4">
                        Brand Source
                        <Icon home="ArrowDown" class="cursor-pointer" />
                    </div>
                </th>
                <th>
                    <div class="flex items-center gap-4">
                        Store Source
                        <Icon home="ArrowDown" class="cursor-pointer" />
                    </div>
                </th>
                <th>
                    <div class="flex items-center gap-4">
                        AS
                        <Icon home="ArrowDown" class="cursor-pointer" />
                    </div>
                </th>
                <th>
                    <div class="flex items-center gap-4">
                        SOH
                        <Icon home="ArrowDown" class="cursor-pointer" />
                    </div>
                </th>
                <th>
                    <div class="flex items-center gap-4">
                        Actions
                        <Icon home="ArrowDown" class="cursor-pointer" />
                    </div>
                </th>
            </tr>
        )
        const filteredTempRows = filtered4?.map((row, key) => (
            <tr key={key}>
                <td class="text-primary-500  overflow-hidden">
                    {row.shopify_title}
                </td>
                <td class="text-primary-500  overflow-hidden">
                    {(row.product_name) + ' - / - ' + (row.name)}
                </td>
                <td class="text-primary-500  overflow-hidden">
                    {row.sku}
                </td>
                <td class="text-primary-500  overflow-hidden">
                    {row.ActualBrand}
                </td>
                <td class="text-primary-500  overflow-hidden">
                    {row.brand_name}
                </td>
                <td class="text-primary-500  overflow-hidden">
                    {row.available_stock}
                </td>
                <td>
                    {row.stock_on_hand}
                </td>
                <td>
                    {
                        row?.variant_id === 'N/A' ?
                            <>
                                <ArrowPathIcon className="text-error-500 w-5 cursor-pointer" onClick={() => { handleSyncVariant(row) }} />
                            </>
                            :
                            <>
                                <CheckCircleIcon className="text-success-500 w-5 cursor-pointer" />
                            </>
                    }
                </td>
            </tr>
        ))
        setRows(filteredTempRows)
        setColumns(filteredTempColumns)
    }, [search, storeSelected, syncDecider, brandSelected, prod])
    const HandlerSyncDecider = (checker) => {
        if (checker) {
            setSyncDecider([...syncDecider, 'unsync'])
        }
        else {
            let temp = syncDecider;
            temp = temp.filter((item) => (item !== 'unsync'))
            setSyncDecider([...temp])
        }
    }

    const overallLoading = brandsLoading || prodLoading || syncVariantLoading;
    if (overallLoading) {
        dispatch(loadingDecider(true));
    }
    if (!overallLoading) {
        dispatch(loadingDecider(false));
    }

    return (
        <LayoutMain>
            <div className="heading-container">
                <div className="heading-main">
                    <h3>
                        Variants
                    </h3>
                </div>

            </div >
            <div className="px-8">
                <div className="border-b flex gap-4"></div>
                <Tab.Group>
                    <Tab.Panels>
                        <Tab.Panel>
                            <div className="box mt-4">
                                <div className="flex items-center justify-between">
                                    <SearchCustom
                                        query={search}
                                        setQuery={setSearch}
                                        placeholder={'Search by variant name'}
                                    />
                                    <div className="action">
                                        <SelectSearch
                                            listItems={storeList}
                                            selected={storeSelected}
                                            setSelected={setStoreSelected}
                                            placeholder={'Select Brand...'}
                                            query={storeQuery}
                                            setQuery={setStoreQuery}
                                            fullList={true}
                                        />
                                        <SelectSearch
                                            listItems={brandList}
                                            selected={brandSelected}
                                            setSelected={setBrandSelected}
                                            placeholder={'Select Store...'}
                                            query={brandQuery}
                                            setQuery={setBrandQuery}
                                            fullList={true}
                                        />
                                        <Switch.Group>
                                            <Switch.Label className="custom-heading">Show Unpublished</Switch.Label>
                                            <Switch
                                                checked={filter}
                                                onChange={(e) => {
                                                    e ? HandlerSyncDecider(e) : HandlerSyncDecider(e)
                                                    setFilter(e)
                                                }}
                                                className={`${filter ? 'bg-blue-600' : 'bg-gray-200'} relative inline-flex h-6 w-11 items-center rounded-full`}
                                            >
                                                <span
                                                    className={`${filter ? 'translate-x-6' : 'translate-x-1'
                                                        } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                                />
                                            </Switch>
                                        </Switch.Group>
                                    </div>
                                </div>
                            </div>
                            <DataTableCustom
                                rows={rows}
                                columns={columns}
                            />
                        </Tab.Panel>
                    </Tab.Panels>
                </Tab.Group>
            </div>
        </LayoutMain >


    )
}

export default Dashboard