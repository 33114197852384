import React, { useState } from 'react'
import Icon from './Icon'
import _ from "lodash";
import './../styles/pagination.scss';

const Pagination = (props) => {
    return (
        <>
            <div className="flex justify-end py-3 mr-4">
                <div className="flex items-center border rounded-lg">
                    <button className='customButon'
                        onClick={() => {
                            props.prevPage()
                        }}
                        disabled={props.currentIndex <= 1 ? true : false}
                    >
                        <Icon width="11" height="11" home="ChevronLeft" />
                        Previous
                    </button>

                    {
                        Math.floor(props.totalRecords / props.pageLength) > 6 && (props.currentIndex) < (Math.floor(props.totalRecords / props.pageLength) - 1) ?
                            _.range(props.currentIndex, props.currentIndex + 3).map(i => (
                                <button
                                    onClick={() => { props.setCurrentIndex(i) }}
                                    className={`${i === props.currentIndex ? 'active' : ''} customButon`}
                                >
                                    {i}
                                </button>
                            )) : ''
                    }


                    {Math.floor(props.totalRecords / props.pageLength) > 6 && (props.currentIndex) < (Math.floor(props.totalRecords / props.pageLength) - 1) ? <button className='customButon'>...</button> : ''}


                    {
                        Math.floor(props.totalRecords / props.pageLength) > 6 ?
                            _.range(Math.floor(props.totalRecords / props.pageLength) -1, Math.ceil(props.totalRecords / props.pageLength) + 1).map(i => (
                                <button
                                    onClick={() => {
                                        props.changePage(i);
                                    }}
                                    key={i}
                                    className={`${i === props.currentIndex ? 'active' : ''} customButon`}
                                >
                                    {i}
                                </button>
                            )) : ''
                    }

                    {
                        Math.floor(props.totalRecords / props.pageLength) < 7 ?
                            _.range(1, Math.floor(props.totalRecords / props.pageLength) + 2).map(i => {
                                return (
                                    < button
                                        onClick={() => { props.changePage(i) }}
                                        key={i}
                                        className={`${i === props.currentIndex ? 'active' : ''} customButon`}
                                    >
                                        {i}
                                    </button>
                                )

                            }) : ''
                    }


                    <button
                        className='customButon'
                        onClick={() => {
                            props.nextPage()
                        }}
                        disabled={props.currentIndex > (Math.ceil(props.totalRecords / props.pageLength) - 1) ? true : false}
                    >
                        Next
                        <Icon width="11" height="11" home="ChevronRight" />
                    </button>



                </div >
            </div >
        </>
    )
}

export default Pagination
