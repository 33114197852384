import * as yup from "yup";

export const loginSchema = yup.object().shape({
  email: yup
    .string("email should be a string")
    .email("please provide a valid email address")
    .required("email address is required"),
  password: yup
    .string("password should be a string")
    .min(5, "password should have a minimum length of 5")
    .required("password is required"),
});


export const userSchema = yup.object().shape({
  name: yup
    .string("name should be a string")
    .required("name is required"),
  email: yup
    .string("email should be a string")
    .email("please provide a valid email address")
    .required("email address is required")
});


export const authSchema = yup.object().shape({
  authOTP: yup
    .string("OTP should be a string")
    .required("OTP is required")
});


export const brandSchema = yup.object().shape({
  brand: yup
    .object({
      id: yup.string("should be a string").required("brand is required"),
      name: yup.string("should be a string").required("brand is required")
    }),
  brand_name: yup
    .string("brand name should be a string")
    .required("brand name is required"),
  token: yup
    .string("token should be a string")
    .required("token is required"),
});

export const brandUpdateSchema = yup.object().shape({
  id: yup
    .number("Brand Key should be a numeric")
    .required("Brand Key is required"),
  brand_name: yup
    .string("brand name should be a string")
    .required("brand name is required"),
  token: yup
    .string("token should be a string")
    .required("token is required"),
});

export const locatorSchema = yup.object().shape({
  brandSelect: yup
    .object({
      id: yup.string("should be a string").required("brand is required"),
      name: yup.string("should be a string").required("brand is required")
    }),
  companySelect: yup
    .string("Company name should be a string")
    .required("Company name is required"),
  address1: yup
    .string("address1 should be a string")
    .required("address1 is required"),
  city: yup
    .string("city should be a string")
    .required("city is required"),
  statecode: yup
    .string("statecode should be a string")
    .required("statecode is required"),
  zipcode: yup
    .string("zipcode should be a string")
    .required("zipcode is required"),
  address2: yup
    .string("Address2 should be a string")
    .required("Address2 is required"),
});

export const locatorSchemaUpdate = yup.object().shape({
  id: yup
    .number("Locator key should be a numeric")
    .required("Locator key is required"),
  brandSelect: yup
    .object({
      id: yup.string("should be a string").required("brand is required"),
      name: yup.string("should be a string").required("brand is required")
    }),
  companySelect: yup
    .string("Company name should be a string")
    .required("Company name is required"),
  address1: yup
    .string("address1 should be a string")
    .required("address1 is required"),
  city: yup
    .string("city should be a string")
    .required("city is required"),
  statecode: yup
    .string("statecode should be a string")
    .required("statecode is required"),
  zipcode: yup
    .string("zipcode should be a string")
    .required("zipcode is required"),
  address2: yup
    .string("Address2 should be a string")
    .required("Address2 is required"),
});


export const locatorStyleSchema = yup.object().shape({
  brand: yup
    .object({
      id: yup.string("should be a string").required("brand is required"),
      name: yup.string("should be a string").required("brand is required")
    }),
  mapId: yup
    .string("Map Id should be a string")
    .required("Map Id is required"),
});



