import React, { useEffect, useState } from 'react';
import LayoutMain from '../components/LayoutMain';
import { useMutation, useQuery } from 'react-query';
import { ErrorListService, ErrorReloadService } from '../services/service';
import DataTable from 'react-data-table-component';
import Loading from '../components/Loading';
import { useDispatch } from 'react-redux';
import spinner from './../assets/images/spinner.gif'
import { openSnack } from '../ConfigureStore/snackbarData';
import { Tab } from '@headlessui/react';
import SearchCustom from '../components/SearchCustom';
import SelectSearch from '../components/SelectSearch';
import DataTableCustom from '../components/DataTableCustom';
import Icon from '../components/Icon';
import { loadToaster, loadingDecider } from '../ConfigureStore/globalLoader';


const ErrorsList = (props) => {
    const { data: errorData, isLoading: errorDataLoading, refetch: errorRefetch } = useQuery(['errorList'], ErrorListService)
    const { mutate: errorReloadMutate, isLoading: errorReloadLoading } = useMutation(ErrorReloadService)
    // const [showUpdate, setShowUpdate] = useState(false);
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);
    const dispatch = useDispatch();
    const [brandSelected, setBrandSelected] = useState([]);
    const [brands, setBrands] = useState([]);
    const [brandQuery, setBrandQuery] = useState('');
    // const [showDelete, setShowDelete] = useState(false);
    // const handleCloseDelete = () => setShowDelete(false);
    // const [currentRow, setCurrentRow] = useState();
    // const handleCloseUpdate = () => setShowUpdate(false);

    // const handleOnChangeDelete = (row) => {
    //     setCurrentRow(row)
    //     setShowDelete(true);
    // }
    // const handleChangeStatus = (e, row) => {
    //     const payload = {
    //         id: row.id,
    //         status: row?.status?.data[0]
    //     }
    //     updateLocatorStatusMutate(payload, updateLocatorStatusFunctions)
    // }

    // const updateLocatorStatusFunctions = {
    //     onSuccess: (res) => {
    //         if (res?.data?.message) {
    //             dispatch(openSnack({ message: "successfully updated", severity: "success", open: true }))
    //             locatorRefetch();
    //         }
    //         else {
    //             dispatch(openSnack({ message: "something went wrong", severity: "error", open: true }))
    //         }
    //     },
    //     onError: (error) => {
    //         dispatch(openSnack({ message: "something went wrong", severity: "error", open: true }))
    //     }
    // }

    const handleOnChangeUpdate = (row) => {
        const payload = {
            id: row.id
        }
        errorReloadMutate(payload, errorReloadFunctions)
    }
    const errorReloadFunctions = {
        onSuccess: (res) => {
            if (res?.data?.message) {
                dispatch(loadToaster({
                    message: "successfully reload",
                    type: 'success',
                    transition: 'bounce'
                }));
                errorRefetch();
            }
            else {
                dispatch(loadToaster({
                    message: "something went wrong",
                    type: 'error',
                    transition: 'bounce'
                }));
            }
        },
        onError: (error) => {
            dispatch(loadToaster({
                message: "something went wrong",
                type: 'error',
                transition: 'bounce'
            }));
        }
    }

    useEffect(() => {
        if (errorData) {
            const filteredTempColumns = (
                <tr>
                    <th>
                        <div className="flex items-center gap-4">
                            Webhook Name
                            <Icon home="ArrowDown" className="cursor-pointer" />
                        </div>
                    </th>
                    <th>
                        <div className="flex items-center gap-4">
                            Store Name
                            <Icon home="ArrowDown" className="cursor-pointer" />
                        </div>
                    </th>
                    <th>
                        <div className="flex items-center gap-4">
                            Actions
                            <Icon home="ArrowDown" className="cursor-pointer" />
                        </div>
                    </th>
                </tr>
            )
            const filteredTempRows = errorData?.data?.map((row, key) => (
                <tr key={key}>
                    <td className="text-primary-500 font-normal overflow-hidden">
                        {row.reload_function_name}
                    </td>
                    <td className="text-primary-500 font-normal overflow-hidden">
                        {row.slug}
                    </td>
                    <td className="text-primary-500 font-normal overflow-hidden">
                        {<i className={`fas fa-sync-alt text-danger icon-opacity pr-4 cursor-pointer`} onClick={() => { "" }} variant="primary"></i>}
                    </td>
                </tr>
            ))
            setColumns(filteredTempColumns);
            setRows(filteredTempRows);
            const uniq = [...new Set(errorData?.data.map((item) => item?.slug))].map((item, key) => ({ id: key + 1, name: key }));
            setBrands(uniq)
        }
    }, [errorData])

    useEffect(() => {
        if (brandSelected.length === 0) { return setRows(errorData?.data) }
        const filtered = errorData?.data?.filter((item) => (brandSelected === 'Select Store...' ? true : item.slug === brandSelected))
        setRows(filtered)
    }, [brandSelected, errorData])

    const overallLoading = errorDataLoading || errorReloadLoading;

    useEffect(() => {
        if (overallLoading) {
            dispatch(loadingDecider(true))
        }
        else {
            dispatch(loadingDecider(false))
        }
    }, [overallLoading])

    return (
        <LayoutMain>
            <div className="px-8 py-4">
                <div className="flex items-center justify-between">
                    <h3>
                        Failed tasks
                    </h3>
                </div>

            </div >
            <div className="px-8 ">
                <div className="border-b flex gap-4"></div>
                <Tab.Group>
                    <Tab.Panels>
                        <Tab.Panel>
                            <div className="box mt-4">
                                <div className="flex items-center justify-between">
                                    <div></div>
                                    <div className="action">
                                        <SelectSearch
                                            listItems={brands}
                                            selected={brandSelected}
                                            setSelected={setBrandSelected}
                                            placeholder={'Select Store...'}
                                            query={brandQuery}
                                            setQuery={setBrandQuery}
                                            fullList={true}
                                        />
                                    </div>
                                </div>
                            </div>
                            <DataTableCustom
                                rows={rows}
                                columns={columns}
                            />
                        </Tab.Panel>
                    </Tab.Panels>
                </Tab.Group>
            </div>
        </LayoutMain>
    )
}

export default ErrorsList
