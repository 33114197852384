import medusaRequest, { multipartRequest } from "./request"


export default {
  product: {
    list() {
      const path = `/api/products`
      return medusaRequest("GET", path)
    },
    syncVariant(payload) {
      const path = `/api/syncVariant`
      return medusaRequest("POST", path, payload)
    }
  },
  admin: {
    login(payload) {
      const path = `/api/login`
      return medusaRequest("POST", path, payload)
    },
    auth() {
      const path = `/api/user`
      return medusaRequest("GET", path)
    },
    logout() {
      const path = `/api/logout`
      return medusaRequest("GET", path)
    },
    authOTP(payload) {
      const path = `/api/authOtp`
      return medusaRequest("POST", path, payload)
    }

  },
  brand: {
    listMod() {
      const path = `/api/brandShopifyMod`
      return medusaRequest("GET", path)
    },
    list() {
      const path = `/api/brandShopify`
      return medusaRequest("GET", path)
    },
    listAll() {
      const path = `/api/brandAllShopify`
      return medusaRequest("GET", path)
    },
    create(payload) {
      const path = `/api/brandShopify`
      return medusaRequest("POST", path, payload)
    },
    update(payload) {
      const path = `/api/brandShopify`
      return medusaRequest("PUT", path, payload)
    },
    delete(id) {
      const path = `/api/brandShopify/${id}`
      return medusaRequest("DELETE", path)
    },

  },
  webhook: {
    updateWebhook(payload) {
      const path = `/api/webhookUpdater`
      return medusaRequest("POST", path, payload)
    },
  },
  locator: {
    list() {
      const path = `/api/geolocations`
      return medusaRequest("GET", path)
    },
    listStatus() {
      const path = `/api/geolocationFileStatus`
      return medusaRequest("GET", path)
    },
    create(payload) {
      const path = `/api/geolocations`
      return medusaRequest("POST", path, payload)
    },
    update(payload) {
      const path = `/api/geolocations`
      return medusaRequest("PUT", path, payload)
    },
    delete(id) {
      const path = `/api/geolocations/${id}`
      return medusaRequest("DELETE", path)
    },
    updateStatus(payload) {
      const path = `/api/geolocationsstatus`
      return medusaRequest("POST", path, payload)
    },
    storeName(store_name) {
      const path = `/api/geolocationsOnStores/${store_name}`
      return medusaRequest("GET", path)
    },
    updateStyle(payload) {
      const path = `/api/updateLocatorStyle`
      return medusaRequest("PUT", path, payload)
    },
    styleList() {
      const path = `/api/locatorStyle`
      return medusaRequest("GET", path)
    },
    customCss(store_name) {
      const path = `/api/customCss/${store_name}`
      return medusaRequest("GET", path)
    },
    uploadCsv(payload) {
      const path = `/api/uploadCSV`
      return multipartRequest(path, payload)
    }
  },
  error: {
    list() {
      const path = `/api/errorHandlers`
      return medusaRequest("GET", path)
    },
    reload(payload) {
      const path = `/api/errorHandlers`
      return medusaRequest("POST", path, payload)
    }
  },
  order: {
    sync(payload) {
      const path = `/api/orderSync/${payload?.slug}`;
      return medusaRequest("POST", path, payload);
    }
  },
  users: {
    list() {
      const path = `/api/getUsers`
      return medusaRequest("GET", path)
    },
    delete(id) {
      const path = `/api/deleteUser/${id}`
      return medusaRequest("DELETE", path)
    },
    resetToken(id) {
      const path = `/api/resetToken/${id}`
      return medusaRequest("GET", path)
    },
    resetPassword(payload) {
      const path = `/api/resetPassword/${payload.id}`
      return medusaRequest("POST", path, payload)
    },
    tokenRequest() {
      const path = `/api/getTokens`
      return medusaRequest("GET", path)
    }
  },
}
