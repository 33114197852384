import React, { useEffect, useState } from 'react';
import LayoutMain from '../components/LayoutMain';
import { useMutation, useQuery } from 'react-query';
import { BrandListRepeats, DeleteLocatorService, LocatorListService, updateLocatorService, updateLocatorUpdateStatus } from '../services/service';
import './../styles/brandsList.scss';
import { useDispatch } from 'react-redux';
import { openSnack } from '../ConfigureStore/snackbarData';
import { loadToaster, loadingDecider } from '../ConfigureStore/globalLoader';
import Icon from '../components/Icon';
import Badge from '../components/Badge';
import { Dialog, Tab } from '@headlessui/react';
import DataTableCustom from '../components/DataTableCustom';
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline';
import SelectSearch from '../components/SelectSearch';
import SearchCustom from '../components/SearchCustom';
import CustomModal from '../components/CustomModal';
import { locatorSchemaUpdate } from '../utils/formSchema';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { smartySearch } from '../smartyServices/service';
import Button from '../components/Button';




const BrandsList = (props) => {
    const { mutate: updateLocatorMutate, isLoading: updateLocatorLoading } = useMutation(updateLocatorService)
    const { data: locatorData, isLoading: locatorDataLoading, refetch: locatorRefetch } = useQuery(['locatorList'], LocatorListService)
    const { data: brandsList, isLoading: brandsListLoading, refetch: brandsListRefetch } = useQuery(['brandAllData'], BrandListRepeats)
    const { mutate: updateLocatorStatusMutate, isLoading: updateLocatorStatusLoading } = useMutation(updateLocatorUpdateStatus)
    const { mutate: deleteLocatorMutate, isLoading: deleteLocatorLoading } = useMutation(DeleteLocatorService)
    const [showUpdate, setShowUpdate] = useState(false);
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);
    const dispatch = useDispatch();
    const [search, setSearch] = useState('');
    const [brandSelected, setBrandSelected] = useState([]);
    const [brandListSelected, setBrandListSelected] = useState([]);
    const [brands, setBrands] = useState([]);
    const [brandQuery, setBrandQuery] = useState('');
    const [showDelete, setShowDelete] = useState(false);
    const handleCloseDelete = () => setShowDelete(false);
    const [currentRow, setCurrentRow] = useState();
    const handleCloseUpdate = () => setShowUpdate(false);
    const [brandData, setBrandData] = useState([]);
    const [timer, setTimer] = useState(null)
    const [addressOption, setAddressOption] = useState([])
    const [addressQuery, setAddressQuery] = useState('')
    const [addressSelected, setAddressSelected] = useState([])

    const {
        register,
        getValues,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm({ resolver: yupResolver(locatorSchemaUpdate) })

    useEffect(() => {
        if (errors?.id) {
            dispatch(loadToaster({
                message: errors?.id?.message,
                type: 'error',
                transition: 'bounce'
            }))
        }
        if (errors?.brandSelect) {
            dispatch(loadToaster({
                message: errors?.brandSelect?.message,
                type: 'error',
                transition: 'bounce'
            }));
        }
        if (errors?.address1) {
            dispatch(loadToaster({
                message: errors?.address1?.message,
                type: 'error',
                transition: 'bounce'
            }));
        }
        if (errors?.statecode) {
            dispatch(loadToaster({
                message: errors?.statecode?.message,
                type: 'error',
                transition: 'bounce'
            }));
        }
        if (errors?.city) {
            dispatch(loadToaster({
                message: errors?.city?.message,
                type: 'error',
                transition: 'bounce'
            }));
        }
        if (errors?.zipcode) {
            dispatch(loadToaster({
                message: errors?.zipcode?.message,
                type: 'error',
                transition: 'bounce'
            }));
        }
        if (errors?.address2) {
            dispatch(loadToaster({
                message: errors?.address2?.message,
                type: 'error',
                transition: 'bounce'
            }));
        }
        if (errors?.companySelect) {
            dispatch(loadToaster({
                message: errors?.companySelect?.message,
                type: 'error',
                transition: 'bounce'
            }));
        }
    }, [errors])
    useEffect(() => {
        if (brandsList?.data?.length > 0) {
            const brandData = brandsList?.data?.map((item, key) => ({
                id: item?.id,
                name: item?.name
            }))
            setBrandData(brandData)
        }
    }, [brandsList])

    useEffect(() => {
        clearTimeout(timer)
        const fetchData = async () => {
            if (addressQuery) {
                const payload = {
                    keyword: addressQuery,
                }
                let response = await smartySearch(payload);
                response = response?.data?.suggestions?.map((item) => {
                    return {
                        id: item,
                        name: item?.street_line + ", " + item?.city + ", " + item?.state + ", " + item?.zipcode
                    }
                })
                setAddressOption(response)
            }
        }
        const newTimer = setTimeout(() => {
            fetchData();
        }, 700)
        setTimer(newTimer)
    }, [addressQuery])
    const handleBrandUpdateShopify = (data) => {
        const payload = {
            id: data?.id,
            brandId: data?.brandSelect?.id,
            brandLabel: data?.brandSelect?.name,
            city: data?.city,
            state: data?.statecode,
            streetline: data?.address1,
            streetline2: data?.address2,
            zipcode: data?.zipcode,
            companyName: data?.companySelect,
            address_id: data?.address_id
        }
        updateLocatorMutate(payload, LocatorUpdateModal)
    }
    const LocatorUpdateModal = {
        onSuccess: ((res) => {
            locatorRefetch();
            handleCloseUpdate();
            dispatch(loadToaster({
                message: "Successfully updated",
                type: 'success',
                transition: 'bounce'
            }))
            reset();
        }),
        onError: ((err) => {
            dispatch(loadToaster({
                message: "Something went wrong",
                type: 'error',
                transition: 'bounce'
            }))
        })
    }


    const handleChangeStatus = (row) => {
        dispatch(loadToaster(true))
        const payload = {
            id: row?.id,
            status: row?.status?.data[0]
        }
        updateLocatorStatusMutate(payload, updateLocatorStatusFunctions)
    }

    const updateLocatorStatusFunctions = {
        onSuccess: (res) => {
            if (res?.data?.message) {
                dispatch(loadToaster({
                    message: "Successfully Updated",
                    type: 'success',
                    transition: 'bounce'
                }))
                locatorRefetch();
            }
            else {
                dispatch(loadToaster({
                    message: "Something went wrong",
                    type: 'error',
                    transition: 'bounce'
                }))
            }
        },
        onError: (error) => {
            dispatch(loadToaster({
                message: "Something went wrong",
                type: 'error',
                transition: 'bounce'
            }))
        }
    }

    const handleOnChangeUpdate = (row) => {
        setCurrentRow(row)
        setShowUpdate(true);
    }

    const handleOnChangeDelete = (row) => {
        setCurrentRow(row);
        setShowDelete(true);
    }

    useEffect(() => {
        setBrandListSelected({ id: currentRow?.brand_id, name: currentRow?.store_name });
        setAddressSelected({
            name: currentRow?.address1 !== null ? currentRow?.address1 + " " + currentRow?.city + " " + currentRow?.state + " " + currentRow?.zipcode : "",
            id: {
                street_line: currentRow?.address1,
                street_line2: (currentRow?.address2 ? currentRow?.address2 : ""),
                city: currentRow?.city,
                state: currentRow?.state,
                zipcode: currentRow?.zipcode
            }
        })
        reset(
            {
                id: currentRow?.id,
                brandSelect: {
                    id: currentRow?.brand_id,
                    name: currentRow?.store_name
                },
                companySelect: currentRow?.company_name,
                address2: (currentRow?.address2 ? currentRow?.address2 : ""),
                completeAddress: {
                    name: currentRow?.address1 !== null ? currentRow?.address1 + " " + currentRow?.city + " " + currentRow?.state + " " + currentRow?.zipcode : "",
                    id: {
                        street_line: currentRow?.address1,
                        street_line2: currentRow?.address2,
                        city: currentRow?.city,
                        state: currentRow?.state,
                        zipcode: currentRow?.zipcode
                    }
                },
                address_id: currentRow?.address_id
            }
        )

    }, [currentRow]);

    useEffect(() => {
        reset({
            ...getValues(),
            brandSelect: brandListSelected
        })
    }, [brandListSelected])

    useEffect(() => {
        const payload = {
            ...getValues(),
            address1: addressSelected?.id?.street_line,
            city: addressSelected?.id?.city,
            zipcode: addressSelected?.id?.zipcode,
            statecode: addressSelected?.id?.state,
        }
        reset(payload);
    }, [addressSelected])



    useEffect(() => {
        if (locatorData) {
            const uniq = [...new Set(locatorData?.data?.map((item) => item?.store_name))].map((item, key) => (
                { id: key, name: item === null ? 'none' : item }
            ))
            setBrands(uniq)
        }
    }, [locatorData])


    const handleDeleteLocator = (data) => {
        deleteLocatorMutate(data, deleteLocatorFunctions)
    }
    const deleteLocatorFunctions = {
        onSuccess: ((res) => {
            handleCloseDelete();
            locatorRefetch();
            dispatch(loadToaster({
                message: "Successfully deleted",
                type: 'success',
                transition: 'bounce'
            }))
        }),
        onError: ((err) => {
            dispatch(loadToaster({
                message: "Something went wrong",
                type: 'error',
                transition: 'bounce'
            }))
        })
    }

    useEffect(() => {
        if (search === '' && brandSelected.length === 0) {
            const filteredTempColumns = (
                <tr>
                    <th>
                        <div className="flex items-center gap-4">
                            Company Name
                            <Icon home="ArrowDown" className="cursor-pointer" />
                        </div>
                    </th>
                    <th>
                        <div className="flex items-center gap-4">
                            Address 1
                            <Icon home="ArrowDown" className="cursor-pointer" />
                        </div>
                    </th>

                    <th>
                        <div className="flex items-center gap-4">
                            Store Name
                            <Icon home="ArrowDown" className="cursor-pointer" />
                        </div>
                    </th>
                    <th>
                        <div className="flex items-center gap-4">
                            Status
                            <Icon home="ArrowDown" className="cursor-pointer" />
                        </div>
                    </th>
                    <th>
                        <div className="flex items-center gap-4">
                            Action
                            <Icon home="ArrowDown" className="cursor-pointer" />
                        </div>
                    </th>
                </tr>
            )
            const filteredTempRows = locatorData?.data?.map((row, key) => (
                <tr key={key}>
                    <td className="text-primary-500 font-normal overflow-hidden">
                        {row?.company_name ? row?.company_name.replace(/ *\([^)]*\) */g, "") : ""}
                    </td>
                    <td className="text-primary-500 font-normal overflow-hidden">
                        {row?.address1 !== null ? row?.address1 + " " + row?.city + " " + row?.state + " " + row?.zipcode : ""}
                    </td>

                    <td className="text-primary-500 font-normal overflow-hidden">
                        {row?.store_name}
                    </td>
                    <td onClick={() => { handleChangeStatus(row) }} className="text-primary-500 font-normal overflow-hidden cursor-pointer">
                        <Badge view={`${row?.status?.data[0] === 1 ? 'success' : 'error'}`} title={row?.status?.data[0] === 1 ? 'active' : 'inactive'} />
                    </td>
                    <td className="text-primary-500 font-normal overflow-hidden flex mt-2">
                        <PencilSquareIcon className="w-5 text-success-500 cursor-pointer mr-3" onClick={() => { handleOnChangeUpdate(row); }} />
                        <TrashIcon className="w-5 text-error-500 cursor-pointer mr-4" onClick={() => { handleOnChangeDelete(row); }} />
                    </td>
                </tr >
            ))

            setColumns(filteredTempColumns);
            setRows(filteredTempRows);
        }
        const value = search.toLowerCase();
        const filtered = locatorData?.data?.filter((item) => (
            item?.address_text?.toLowerCase().includes(value) ||
            item?.store_name?.toLowerCase().includes(value) ||
            item?.company_name?.toLowerCase().includes(value)
        ))

        const filtered2 = filtered?.filter((item) => (brandSelected.length === 0 ? true : item.store_name === brandSelected?.name))

        const filteredTempColumns = (
            <tr>
                <th>
                    <div className="flex items-center gap-4">
                        Company Name
                        <Icon home="ArrowDown" className="cursor-pointer" />
                    </div>
                </th>
                <th>
                    <div className="flex items-center gap-4">
                        Address 1
                        <Icon home="ArrowDown" className="cursor-pointer" />
                    </div>
                </th>

                <th>
                    <div className="flex items-center gap-4">
                        Store Name
                        <Icon home="ArrowDown" className="cursor-pointer" />
                    </div>
                </th>
                <th>
                    <div className="flex items-center gap-4">
                        Status
                        <Icon home="ArrowDown" className="cursor-pointer" />
                    </div>
                </th>
                <th>
                    <div className="flex items-center gap-4">
                        Action
                        <Icon home="ArrowDown" className="cursor-pointer" />
                    </div>
                </th>
            </tr>
        )
        const filteredTempRows = filtered2?.map((row, key) => (
            <tr key={key}>
                <td className="text-primary-500 font-normal overflow-hidden">
                    {row?.company_name ? row?.company_name.replace(/ *\([^)]*\) */g, "") : ""}
                </td>
                <td className="text-primary-500 font-normal overflow-hidden">
                    {row?.address1 !== null ? row?.address1 + " " + row?.city + " " + row?.state + " " + row?.zipcode : ""}
                </td>


                <td className="text-primary-500 font-normal overflow-hidden">
                    {row?.store_name}
                </td>
                <td onClick={() => { handleChangeStatus(row) }} className="text-primary-500 font-normal overflow-hidden cursor-pointer">
                    <Badge title={`${row?.status?.data[0] === 1 ? 'active' : 'inactive'}`} view={`${row?.status?.data[0] === 1 ? 'success' : 'error'}`} onClick={e => handleChangeStatus(e, row)} />
                </td>
                <td className="text-primary-500 font-normal overflow-hidden flex mt-2">
                    <PencilSquareIcon className="w-5 text-success-500 cursor-pointer mr-3" onClick={() => { handleOnChangeUpdate(row); }} />
                    <TrashIcon className="w-5 text-error-500 cursor-pointer mr-4" onClick={() => { handleOnChangeDelete(row); }} />
                </td>
            </tr>
        ))

        setColumns(filteredTempColumns);
        setRows(filteredTempRows);
    }, [search, brandSelected, locatorData])

    const overallLoading = locatorDataLoading || updateLocatorStatusLoading || updateLocatorLoading || deleteLocatorLoading || brandsListLoading;

    useEffect(() => {
        if (overallLoading) {
            dispatch(loadingDecider(true));
        }
        else {
            dispatch(loadingDecider(false));
        }
    }, [overallLoading])


    return (
        <LayoutMain>
            <CustomModal
                open={showDelete}
                closeModal={() => setShowDelete(false)}
                modalComponent={
                    <>
                        <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                            <Dialog.Title
                                as="h3"
                                className="text-lg font-medium leading-6 text-gray-900"
                            >
                                Are you sure to delete?
                            </Dialog.Title>

                            <div className="mt-4">
                                {/* <div className="border-b flex gap-4 mb-3"></div> */}
                                <div className='flex justify-end'>
                                    <Button type="submit" className="btn btn-success mx-3" title='Yes' functionality={() => handleDeleteLocator(currentRow?.id)} />
                                    <Button type="submit" className="btn btn-danger" title='No' functionality={() => setShowDelete(false)} />
                                </div>

                            </div>
                        </Dialog.Panel>
                    </>
                }
            />
            <CustomModal
                open={showUpdate}
                closeModal={() => setShowUpdate(false)}
                modalComponent={
                    <>
                        <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                            <Dialog.Title
                                as="h3"
                                className="text-lg font-medium leading-6 text-gray-900"
                            >
                                Update Store Locator
                            </Dialog.Title>

                            <div className="mt-4">
                                <div className="border-b flex gap-4"></div>
                                <form onSubmit={handleSubmit(handleBrandUpdateShopify)} className="space-y-4 mt-2">
                                    <div>
                                        <label for="brand_name">Stores</label>
                                        <SelectSearch
                                            listItems={brandData}
                                            selected={brandListSelected}
                                            setSelected={setBrandListSelected}
                                            placeholder={'Select stores'}
                                            query={brandQuery}
                                            setQuery={setBrandQuery}
                                            fullList={false}
                                        />
                                    </div>
                                    <div>
                                        <label for="brand_name">Company Name</label>
                                        <input   {...register('companySelect')} type="text" className="w-full my-1 placeholder:font-medium" placeholder="Enter company name" />
                                    </div>
                                    <div>
                                        <label for="brand_name">Address autocomplete</label>
                                        <SelectSearch
                                            listItems={addressOption}
                                            selected={addressSelected}
                                            setSelected={setAddressSelected}
                                            placeholder={'Enter address'}
                                            query={addressQuery}
                                            setQuery={setAddressQuery}
                                            fullList={true}
                                            upperBox={true}
                                        />
                                    </div>
                                    <div>
                                        <label for="brand_name">Address 1</label>
                                        <input   {...register('address1')} type="text" className="w-full my-1 placeholder:font-medium" placeholder="Enter address 1" />
                                    </div>
                                    <div className='grid grid-cols-3 gap-4'>
                                        <div >
                                            <label for="brand_name">State code</label>
                                            <input   {...register('statecode')} type="text" className="w-full my-1 placeholder:font-medium" placeholder="Enter state code" />
                                        </div>
                                        <div>
                                            <label for="brand_name">City</label>
                                            <input   {...register('city')} type="text" className="w-full my-1 placeholder:font-medium" placeholder="Enter city" />
                                        </div>
                                        <div >
                                            <label for="brand_name">Postal code</label>
                                            <input   {...register('zipcode')} type="text" className="w-full my-1 placeholder:font-medium" placeholder="Enter postal code" />
                                        </div>

                                    </div>
                                    <div>
                                        <label for="brand_name">Address 2</label>
                                        <input   {...register('address2')} type="text" className="w-full my-1 placeholder:font-medium" placeholder="Enter Address 2" />
                                    </div>
                                    <Button type="submit" className="btn btn-primary" title='Submit' />
                                </form>

                            </div>
                        </Dialog.Panel>
                    </>
                }
            />
            <div className="heading-container">
                <div className="heading-main">
                    <h3>
                        Store Locators
                    </h3>
                </div>

            </div >
            <div className="px-8 ">
                <div className="border-b flex gap-4"></div>
                <Tab.Group>
                    <Tab.Panels>
                        <Tab.Panel>
                            <div className="box mt-4">
                                <div className="flex items-center justify-between">
                                    <SearchCustom
                                        query={search}
                                        setQuery={setSearch}
                                        placeholder={'Search by store, company name'}
                                    />
                                    <div className="action">
                                        <SelectSearch
                                            listItems={brands}
                                            selected={brandSelected}
                                            setSelected={setBrandSelected}
                                            placeholder={'Select Store...'}
                                            query={brandQuery}
                                            setQuery={setBrandQuery}
                                            fullList={true}
                                        />
                                    </div>
                                </div>
                            </div>
                            <DataTableCustom
                                rows={rows}
                                columns={columns}
                            />
                        </Tab.Panel>
                    </Tab.Panels>
                </Tab.Group>
            </div>
        </LayoutMain>
    )
}

export default BrandsList
