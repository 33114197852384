import { createSlice } from '@reduxjs/toolkit'
import { toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const initialState = {
    loading: false,
}

export const GlobalLoader = createSlice({
    name: 'globalLoader',
    initialState,
    reducers: {
        loadingDecider(state, action) {
            state.loading = action.payload;
        },
        loadToaster(state, action) {
            toast(action.payload.message, {
                position: toast.POSITION.TOP_RIGHT,
                type: action.payload.type,
                transition: Bounce,
                hideProgressBar: true,
                autoClose: 1000,
                bodyClassName: 'font-semibold text-primary-500 rounded-lg',
                toastClassName: 'border-2 border-primary-200 rounded-lg shadow-none',
            });
        }
    },
})

// Action creators are generated for each case reducer function

export const { loadingDecider, loadToaster } = GlobalLoader.actions
export default GlobalLoader.reducer