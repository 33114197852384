import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    message:"",
    severity:"",
    open:false
}

export const SnackbarData = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    openSnack(state,action) {
      state.message = action.payload.message
      state.open = true
      state.severity = action.payload.severity
    },
    closeSnack(state) {
        state.open = false
    },
  },
})

// Action creators are generated for each case reducer function
 
export const { openSnack, closeSnack } = SnackbarData.actions
export default SnackbarData.reducer