import React, { useEffect, useState } from 'react';
import LayoutMain from '../components/LayoutMain';
import { useQuery } from 'react-query';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { loadToaster, loadingDecider } from '../ConfigureStore/globalLoader';
import { Dialog, Tab } from '@headlessui/react';
import DataTableCustom from '../components/DataTableCustom';
import { DeleteUsersService, GetUsersService, ResetPasswordService, ResetTokenService } from '../services/service';
import Icon from '../components/Icon';
import { InboxArrowDownIcon, PencilSquareIcon, TrashIcon, ArrowPathRoundedSquareIcon } from '@heroicons/react/24/outline';
import CustomModal from '../components/CustomModal';
import Button from '../components/Button';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { userSchema } from '../utils/formSchema';

const UserList = () => {
    const { data: UsersData, isLoading: UsersDataLoading, refetch: UsersDataRefetch } = useQuery(['UsersList'], GetUsersService)
    const { mutate: deleteUserMutate, isLoading: deleteBrandShopifyLoading } = useMutation(DeleteUsersService)
    const { mutate: resetTokenMutate, isLoading: resetTokenLoading } = useMutation(ResetTokenService)
    const { mutate: resetPasswordMutate, isLoading: resetPasswordLoading } = useMutation(ResetPasswordService)
    const dispatch = useDispatch();
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);
    const [showCreate, setShowCreate] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [showReset, setShowReset] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [currentRow, setCurrentRow] = useState();

    const {
        register,
        getValues,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm({ resolver: yupResolver(userSchema) })

    const handleOnChangeCreate = () => {
        setShowCreate(true);
    }

    const handleOnChangeDelete = (row) => {
        setCurrentRow(row)
        setShowDelete(true);
    }
    const handlePasswordReset = (row) => {
        setCurrentRow(row)
        setShowPassword(true);
    }
    const handleOnResetToken = (row) => {
        setCurrentRow(row)
        setShowReset(true);
    }

    const handleUserResetPassword = () => {
        const payload = {
            id: currentRow?.id,
            email: currentRow?.email,
        }
        resetPasswordMutate(payload, resetPasswordUserFunctions)
    }
    const resetPasswordUserFunctions = {
        onSuccess: ((res) => {
            dispatch(loadToaster({
                message: "Successfully Password Reset",
                type: 'success',
                transition: 'bounce'
            }))
        }),
        onError: ((err) => {
            dispatch(loadToaster({
                message: "something went wrong",
                type: 'error',
                transition: 'bounce'
            }))
        }),
        onSettled: (() => {
            setShowReset(false)
        })
    }

    const handleOnUserCreate = (data) => {

    }



    const handleUserResetToken = (data) => {
        resetTokenMutate(data, resetTokenUserFunctions)
    }
    const resetTokenUserFunctions = {
        onSuccess: ((res) => {
            dispatch(loadToaster({
                message: "Successfully Token Reset",
                type: 'success',
                transition: 'bounce'
            }))
        }),
        onError: ((err) => {
            dispatch(loadToaster({
                message: "something went wrong",
                type: 'error',
                transition: 'bounce'
            }))
        }),
        onSettled: (() => {
            setShowReset(false)
        })
    }



    const handleUserDeleteShopify = (data) => {
        deleteUserMutate(data, deleteUserFunctions)
    }
    const deleteUserFunctions = {
        onSuccess: ((res) => {
            UsersDataRefetch();
            dispatch(loadToaster({
                message: "Successfully deleted",
                type: 'success',
                transition: 'bounce'
            }))
        }),
        onError: ((err) => {
            dispatch(loadToaster({
                message: "something went wrong",
                type: 'error',
                transition: 'bounce'
            }))
        }),
        onSettled: (() => {
            setShowDelete(false)
        })
    }

    useEffect(() => {
        if (UsersData) {
            const filteredTempColumns = (
                <tr>
                    <th>
                        <div class="flex items-center gap-4">
                            Name
                            <Icon home="ArrowDown" class="cursor-pointer" />
                        </div>
                    </th>
                    <th>
                        <div class="flex items-center gap-4">
                            Email
                            <Icon home="ArrowDown" class="cursor-pointer" />
                        </div>
                    </th>
                    <th>
                        <div class="flex items-center gap-4">
                            Actions
                            <Icon home="ArrowDown" class="cursor-pointer" />
                        </div>
                    </th>
                </tr>
            )
            const filteredTempRows = UsersData?.data?.map((row, key) => (
                <tr key={key}>
                    <td class="text-primary-500  overflow-hidden">
                        {row.name}
                    </td>
                    <td class="text-primary-500 overflow-hidden">
                        {row.email}
                    </td>
                    <td class="text-primary-500  overflow-hidden flex mt-2">
                        {/* <PencilSquareIcon className="w-5 text-success-500 cursor-pointer mr-4" onClick={() => { handleOnChangeUpdate(row); }} /> */}
                        {/* <TrashIcon className="w-5 text-error-500 cursor-pointer mr-4" onClick={() => { handleOnChangeDelete(row); }} /> */}
                        <InboxArrowDownIcon className="w-5 text-primary-500 cursor-pointer mr-4" onClick={() => { handlePasswordReset(row); }} />
                        <ArrowPathRoundedSquareIcon className="w-5 text-error-900 cursor-pointer " onClick={() => { handleOnResetToken(row); }} />
                    </td>
                </tr>
            ))
            setColumns(filteredTempColumns);
            setRows(filteredTempRows);
        }
    }, [UsersData])
    const overallLoading = UsersDataLoading || deleteBrandShopifyLoading || resetTokenLoading || resetPasswordLoading;
    useEffect(() => {
        if (overallLoading) {
            dispatch(loadingDecider(true));
        }
        else {
            dispatch(loadingDecider(false));
        }
    }, [overallLoading])

    return (
        <LayoutMain>
            <CustomModal
                open={showCreate}
                closeModal={() => setShowCreate(false)}
                modalComponent={
                    <>
                        <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                            <Dialog.Title
                                as="h3"
                                className="text-lg font-medium leading-6 text-gray-900"
                            >
                                Create User
                            </Dialog.Title>

                            <div className="mt-4">
                                <div className="border-b flex gap-4"></div>
                                <form onSubmit={handleSubmit(handleOnUserCreate)} className="space-y-4 mt-2">
                                    <div>
                                        <label for="brand_name">Name</label>
                                        <input   {...register('name')} type="text" className="w-full my-1 placeholder:font-medium" placeholder="Enter a name" />
                                    </div>
                                    <div>
                                        <label for="brand_name">Email</label>
                                        <input   {...register('email')} type="email" className="w-full my-1 placeholder:font-medium" placeholder="Enter an email" />
                                    </div>
                                    <Button type="submit" className="btn btn-primary" title='Create User' />
                                </form>

                            </div>
                        </Dialog.Panel>
                    </>
                }
            />
            <CustomModal
                open={showReset}
                closeModal={() => setShowReset(false)}
                modalComponent={
                    <>
                        <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                            <Dialog.Title
                                as="h3"
                                className="text-lg font-medium leading-6 text-gray-900"
                            >
                                Are you sure to Reset Token?
                            </Dialog.Title>

                            <div className="mt-4">
                                <div className='flex justify-end'>
                                    <Button type="submit" className="btn btn-success mx-3" title='Yes' functionality={() => handleUserResetToken(currentRow?.id)} />
                                    <Button type="submit" className="btn btn-danger" title='No' functionality={() => setShowReset(false)} />
                                </div>

                            </div>
                        </Dialog.Panel>
                    </>
                }
            />
            <CustomModal
                open={showPassword}
                closeModal={() => setShowPassword(false)}
                modalComponent={
                    <>
                        <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                            <Dialog.Title
                                as="h3"
                                className="text-lg font-medium leading-6 text-gray-900"
                            >
                                Are you sure to Reset Password?
                            </Dialog.Title>

                            <div className="mt-4">
                                <div className='flex justify-end'>
                                    <Button type="submit" className="btn btn-success mx-3" title='Yes' functionality={() => handleUserResetPassword()} />
                                    <Button type="submit" className="btn btn-danger" title='No' functionality={() => setShowPassword(false)} />
                                </div>

                            </div>
                        </Dialog.Panel>
                    </>
                }
            />
            <CustomModal
                open={showDelete}
                closeModal={() => setShowDelete(false)}
                modalComponent={
                    <>
                        <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                            <Dialog.Title
                                as="h3"
                                className="text-lg font-medium leading-6 text-gray-900"
                            >
                                Are you sure to delete?
                            </Dialog.Title>

                            <div className="mt-4">
                                <div className='flex justify-end'>
                                    <Button type="submit" className="btn btn-success mx-3" title='Yes' functionality={() => handleUserDeleteShopify(currentRow?.id)} />
                                    <Button type="submit" className="btn btn-danger" title='No' functionality={() => setShowDelete(false)} />
                                </div>

                            </div>
                        </Dialog.Panel>
                    </>
                }
            />
            <div className="px-8 py-4">
                <div className="flex items-center justify-between">
                    <h3>
                        Users
                    </h3>
                </div>

            </div >
            <div className="px-8 ">
                <div className="border-b flex gap-4"></div>
                <Tab.Group>
                    <Tab.Panels>

                        <Tab.Panel>
                            <div className="box mt-4">
                                <div className="flex items-center justify-between">
                                    <div></div>
                                    <div className="action">
                                        <Button type="submit" className="btn btn-primary p-4" title='Create User' functionality={() => { handleOnChangeCreate() }} />
                                    </div>
                                </div>
                            </div>
                            <DataTableCustom
                                rows={rows}
                                columns={columns}
                            />
                        </Tab.Panel>
                    </Tab.Panels>
                </Tab.Group>
            </div>
        </LayoutMain>
    )
}

export default UserList