import { createSlice } from '@reduxjs/toolkit'
import { authService } from '../services/service'


const initialState = {
  customer: null,
  loading: false,
  error: null
}


export const customerLogin = createSlice({
  name: 'customer',
  initialState,
  extraReducers: (builder) => (
    builder.addCase(authService.pending, (state) => {
      state.loading = true
      state.error = null
    }),
    builder.addCase(authService.fulfilled, (state, action) => {
      state.customer = action.payload.data
      state.loading = false
    }),
    builder.addCase(authService.rejected, (state, action) => {
      state.customer = null
      state.loading = false
      state.error = action.error
    })
  ),
})

// Action creators are generated for each case reducer function

export default customerLogin.reducer