import React from 'react'
import Header from './Header';
import Footer from './Footer';
import Sidebar from './Sidebar';


const LayoutMain = ({ children }) => {
    return (
        <>
            <Sidebar />
            <div className="lg:ml-[60px]">
                < Header />
                {children}
            </div>
            <Footer />
        </>

    )
}

export default LayoutMain