import React, { useState, Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'

const CustomModal = (props) => {
    return (
        <>
            <Transition appear show={props.open} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={props.closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            {
                                props.modalComponent
                            }
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}

export default CustomModal