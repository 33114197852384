import React from 'react'
import './../styles/button.scss';
import Icon from './Icon';

const Button = (props) => {
  return (
    <>
      <button type={props.type} className={
        `text-xs font-base h-7 ${props.iconPosition === 'left ' ? 'flex-row-reverse ' : ' '}
        ${props.active === true ? 'active ' : ' '}
        ${props.className}`
      } disabled={props.disabled} onClick={props.functionality}>
        {props.title}
        {
          props.iconName !== null &&
          <Icon home={props.iconName} />
        }
        {props.active &&
          <span className="notified"></span>
        }
      </button>
    </>
  )
}

export default Button
