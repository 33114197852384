import React from 'react'



const Footer = () => {
  return (
    <>
      <div className="main-footer">
        <strong><a href="https://adminlte.io"></a>.</strong>
        <div className="float-right d-none d-sm-inline-block">
        </div>
      </div>
      <aside className="control-sidebar control-sidebar-dark">
      </aside>
    </>
  )
}

export default Footer