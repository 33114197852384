import { configureStore, } from '@reduxjs/toolkit';
import customerLogin from './customer';
import SnackbarData from './snackbarData';
import GlobalLoader from './globalLoader';
import sidebarMenu from './sidebarMenu';


export const Store = configureStore({
  reducer: {
    customerLogin,
    SnackbarData,
    GlobalLoader,
    sidebarMenu
  },
})

