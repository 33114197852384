import React, { useEffect, useState } from 'react'
import LayoutMain from '../components/LayoutMain'
import { useMutation, useQuery } from 'react-query'
import { AllBrandList, CreateShopifyBrandService, styleLocatorListService, updateLocatorStyleService } from '../services/service'
import spinner from './../assets/images/spinner.gif'
import { brandSchema, locatorStyleSchema } from '../utils/formSchema'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation } from 'react-router-dom';
import Select from 'react-select';
import { useDispatch } from 'react-redux'
import { openSnack } from '../ConfigureStore/snackbarData'
import './../styles/locatorstyle.scss';
import { storeLocatorCss } from '../utils/constant'
import SelectSearch from '../components/SelectSearch'
import Button from '../components/Button'
import { loadToaster, loadingDecider } from '../ConfigureStore/globalLoader'


const LocatorsStyle = () => {
    const dispatch = useDispatch();
    const { data: brands, isLoading: brandsLoading, refetch: brandsRefetch } = useQuery(['brandsData'], AllBrandList)
    const { data: styleData, isLoading: styleLoading, refetch: styleRefetch } = useQuery(['styleData'], styleLocatorListService)
    const { mutate: updateLocatorStyleMutate, isLoading: updateLocatorStyleLoading } = useMutation(updateLocatorStyleService)
    const [brandOptions, setBrandOptions] = useState([])
    const [brandSelected, setBrandSelected] = useState([])
    const [brandQuery, setBrandQuery] = useState('')

    const {
        control,
        register,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm({ resolver: yupResolver(locatorStyleSchema) })


    const handleLocatorStyle = (data) => {
        const payload = {
            custom_css: JSON.stringify({
                ...data,
                brand: undefined
            }),
            brand_id: parseInt(data?.brand?.id)
        }
        updateLocatorStyleMutate(payload, createBrandShopifyFunctions)
    }
    const changeBrand = (current) => {
        let payload = {}
        let checker = true;
        if (styleData?.data?.length > 0) {
            for (let i = 0; i < styleData?.data?.length; i++) {
                if (String(styleData?.data[i]?.brand_id) === current?.id) {
                    let customCss = JSON.parse(styleData?.data[i]?.custom_css)
                    payload = {
                        ...storeLocatorCss,
                        ...customCss,
                        brand: current
                    }
                    checker = false;
                }
            }
            if (checker) {
                const payload = {
                    ...storeLocatorCss,
                    brand: current
                }
                reset(payload)
            }
            else {
                reset(payload)
            }
        }
    }

    if (errors) {
        if (errors?.id) {
            dispatch(loadToaster({
                message: errors?.id?.message,
                type: 'error',
                transition: 'bounce'
            }))
        }
        if (errors?.brand) {
            if (errors?.brand?.name) {
                dispatch(loadToaster({
                    message: errors?.brand?.label?.message,
                    type: 'error',
                    transition: 'bounce'
                }))
            }
        }
        if (errors?.mapId) {
            dispatch(loadToaster({
                message: errors?.brand?.mapId?.message,
                type: 'error',
                transition: 'bounce'
            }))
        }
    }
    const createBrandShopifyFunctions = {
        onSuccess: ((res) => {
            if (res?.data?.error) {
                dispatch(loadToaster({
                    message: "something went wrong",
                    type: 'error',
                    transition: 'bounce'
                }))
                return;
            }
            brandsRefetch();
            styleRefetch();
            reset();
            setBrandSelected([])
            dispatch(loadToaster({
                message: "successfully created",
                type: 'success',
                transition: 'bounce'
            }))

        }),
        onError: ((err) => {
            dispatch(loadToaster({
                message: "something went wrong",
                type: 'error',
                transition: 'bounce'
            }))
        })

    }
    useEffect(() => {
        if (brands?.data?.length > 0) {
            const brandData = brands?.data?.map((item, key) => ({
                id: item?.id,
                name: item?.name
            }))
            setBrandOptions(brandData)
        }
    }, [brands])

    useEffect(() => {
        if (brandSelected?.name) {
            changeBrand(brandSelected)
        }
    }, [brandSelected])

    const overAllLoading = brandsLoading || styleLoading || updateLocatorStyleLoading;
    useEffect(() => {
        if (overAllLoading) {
            dispatch(loadingDecider(true))
        }
        else {
            dispatch(loadingDecider(false))
        }
    }, [overAllLoading])


    return (
        <LayoutMain>
            <div className="px-8 py-4">
                <div className="flex items-center justify-between">
                    <h3>
                        Store Locators Style
                    </h3>
                </div>

            </div >
            <div className="px-8 ">
                <div className="border-b flex gap-4"></div>
                <div className="mt-4 border border-primary-200 rounded-lg shadow p-5 w-1/2">
                    <form onSubmit={handleSubmit(handleLocatorStyle)} className="space-y-4 mt-2">
                        <div>
                            <label htmlFor="brand_name">Stores</label>
                            <SelectSearch
                                listItems={brandOptions}
                                selected={brandSelected}
                                setSelected={setBrandSelected}
                                placeholder={'Select stores'}
                                query={brandQuery}
                                setQuery={setBrandQuery}
                                fullList={false}
                            />
                        </div>
                        <div>
                            <label htmlFor="brand_name">MAP ID</label>
                            <input  {...register('mapId')} type="text" placeholder='example: 905e8fe663c81e3c' className="w-full my-1 placeholder:font-medium" />
                        </div>
                        <div className="mt-4 border border-primary-200 rounded-lg shadow p-5 w-full">
                            <h5>
                                Header Section
                            </h5>
                            <div className="border-b flex gap-4 mb-2"></div>
                            <div className='flex justify-between'>
                                <div className="w-full">
                                    <label htmlFor="brand_name">BACKGROUND COLOR</label>
                                    <input {...register('header_section.background-color')} type="color" className="w-full my-1 placeholder:font-medium" />
                                </div>
                                <div className="w-full px-5">
                                    <label htmlFor="brand_name" >BORDER COLOR</label>
                                    <input  {...register('header_section.border-color')} type="color" className="w-full my-1 placeholder:font-medium" />
                                </div>
                                <div className="w-full">
                                    <label htmlFor="brand_name" >TEXT COLOR</label>
                                    <input {...register('header_section.text-color')} type="color" className="w-full my-1 placeholder:font-medium" />
                                </div>
                            </div>
                        </div>
                        <div className="mt-4 border border-primary-200 rounded-lg shadow p-5 w-full">
                            <h5>
                                Store Left Section
                            </h5>
                            <div className="border-b flex gap-4 mb-2"></div>
                            <div className='flex justify-between'>
                                <div className="w-full">
                                    <label htmlFor="brand_name">BACKGROUND COLOR</label>
                                    <input {...register('left_section.background-color')} type="color" className="w-full my-1 placeholder:font-medium" />
                                </div>
                                <div className="w-full pl-5">
                                    <label htmlFor="brand_name" >TEXT COLOR</label>
                                    <input {...register('left_section.text-color')} type="color" className="w-full my-1 placeholder:font-medium" />
                                </div>
                            </div>
                        </div>
                        <Button type="submit" className="btn btn-primary" title='Update' />
                    </form>
                </div>
            </div>
        </LayoutMain >
    )
}

export default LocatorsStyle