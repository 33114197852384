import React from 'react'
import { useState } from 'react';
import { FileUploader } from "react-drag-drop-files";
import './../styles/fileUploader.scss'


const fileTypes = ["XLSX", "CSV"];

const FileUploaderCustom = (props) => {
    console.log(props.file?.name)
    return (
        <FileUploader
            handleChange={props.handleChange}
            name={props.name}
            types={fileTypes}
            multiple={false}
        >
            <div className='customFileUploader'>
                <span>{props.file?.name ? props.file?.name : "Upload Or Drag a file here"}</span>
            </div>
        </FileUploader>
    );
}

export default FileUploaderCustom