import smartyRequest from "./request"

export default {
  autocomplete: {
    search(payload) {
      const path = ``
      return smartyRequest("GET", path, payload)
    },
  }
}
