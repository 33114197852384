import React from 'react';
import './../styles/svgIcon.scss';

const SvgIcon = (props) => {
  return (
    <>
      {props.getName === 'search' ? (
        <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M18.16 16.4801L16.63 18.0001L11.52 12.8901C11.0494 13.1852 10.562 13.4523 10.06 13.6901C8.85046 14.2738 7.49637 14.4901 6.16517 14.3124C4.83398 14.1346 3.58402 13.5706 2.56996 12.6901C1.21602 11.5704 0.322485 9.9908 0.0602535 8.25353C-0.201978 6.51627 0.18551 4.7433 1.14863 3.27386C2.11175 1.80442 3.58285 0.741675 5.28047 0.28896C6.97809 -0.163755 8.78306 0.0253456 10.35 0.820084C11.5551 1.40542 12.5669 2.32431 13.2653 3.46762C13.9637 4.61094 14.3194 5.93066 14.29 7.27008C14.3065 8.63879 13.9015 9.97943 13.13 11.1101C13.01 11.3001 13.04 11.3901 13.13 11.5401C14.75 13.1534 16.3633 14.7667 17.97 16.3801L18.16 16.4801ZM12.16 7.21008C12.1593 6.22942 11.8702 5.27062 11.3287 4.45299C10.7873 3.63536 10.0173 2.99502 9.1147 2.61163C8.21208 2.22824 7.2167 2.11875 6.25233 2.29677C5.28796 2.4748 4.39723 2.93248 3.69099 3.61287C2.98475 4.29325 2.49414 5.1663 2.28029 6.12336C2.06643 7.08042 2.13876 8.07923 2.48823 8.99551C2.8377 9.91179 3.44893 10.7051 4.2458 11.2766C5.04267 11.8482 5.99003 12.1728 6.96998 12.2101C7.64487 12.2398 8.31875 12.1325 8.951 11.8945C9.58325 11.6566 10.1608 11.293 10.6486 10.8257C11.1365 10.3584 11.5246 9.79714 11.7895 9.17572C12.0544 8.55429 12.1906 7.88562 12.19 7.21008H12.16Z"
            fill="currentColor"
          />
        </svg>
      ) : props.getName === 'locate' ? (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.64084 15.604C8.30253 16.28 7.26642 16.0382 7.26642 15.274L6.02957 9.96716L0.723068 8.73051C-0.0437314 8.73051 -0.274395 7.68875 0.393074 7.35632L14.9394 0.084366C15.5213 -0.237262 16.2506 0.419707 15.9152 1.06005L8.64084 15.604Z"
            fill="currentColor"
          />
        </svg>
      ) : props.getName === 'account' ? (
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M10.109 8.84384C11.5216 9.50283 13.1847 9.7989 14.4872 10.3242C14.9583 10.4708 15.3639 10.7645 15.6384 11.1578C15.9129 11.5512 16.0403 12.0213 16 12.4922C16 13.4472 16 14.4023 16 15.3C15.9199 16.5416 15.5893 16.9332 14.3369 16.9523C10.1357 17.0096 5.93784 17.0096 1.7433 16.9523C0.470919 16.9523 0.1904 16.5416 0.0601557 15.3191C0.0601557 15.195 0.0601557 15.0613 0.0601557 14.9371C0.0601557 10.1618 -0.210379 11.1169 3.47653 9.46463C4.11774 9.16856 5.14969 9.22587 5.94118 8.8534C5.61056 8.32811 4.93925 7.50676 4.63869 7.03879C4.12214 6.12742 3.89984 5.09082 3.99967 4.05932C4.0995 3.02782 4.51706 2.04749 5.1998 1.2416C5.56216 0.832001 6.01699 0.50584 6.53029 0.287527C7.0436 0.069214 7.60204 -0.0355946 8.16385 -0.0190596C8.72566 -0.00252453 9.27628 0.134926 9.77448 0.38301C10.2727 0.631093 10.7055 0.983382 11.0407 1.41351C11.6611 2.15084 12.0451 3.04323 12.1464 3.9835C12.2477 4.92377 12.062 5.87192 11.6118 6.71407C11.3413 7.2489 10.5798 8.0798 10.109 8.84384Z"
            fill="currentColor"
          />
        </svg>
      ) : props.getName === 'cart' ? (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M9.81202 14.1434C8.05202 14.1434 6.30206 14.1434 4.54206 14.1434C3.36206 14.1434 3.08205 13.8134 3.22205 12.6534C3.21713 12.5935 3.21713 12.5333 3.22205 12.4734C3.58677 11.0351 3.64806 9.53669 3.40204 8.07342C3.19204 6.48342 3.05205 4.88343 2.91205 3.28343C2.91754 3.07214 2.84675 2.86594 2.71271 2.70252C2.57867 2.5391 2.39029 2.42938 2.18201 2.39343C1.68136 2.26811 1.19017 2.10771 0.712042 1.91342C0.498347 1.85665 0.314291 1.72064 0.197271 1.53304C0.0802511 1.34543 0.0390427 1.12031 0.0820367 0.903426C0.116502 0.785864 0.175109 0.676793 0.254156 0.583197C0.333202 0.489601 0.430947 0.41354 0.541082 0.359885C0.651217 0.306229 0.771318 0.276161 0.893743 0.271605C1.01617 0.267049 1.1382 0.288105 1.25202 0.333426C2.06202 0.563426 2.86206 0.843426 3.67206 1.10343C3.89869 1.16035 4.10126 1.28795 4.25049 1.46775C4.39973 1.64755 4.48782 1.8702 4.50202 2.10343C4.50202 2.34343 4.57204 2.58342 4.58204 2.82342C4.58204 3.34342 4.84201 3.55342 5.37201 3.61342C7.37201 3.81342 9.37208 4.06343 11.3721 4.28343C13.1721 4.49343 14.982 4.67343 16.782 4.89343C17.882 5.02343 18.132 5.37343 17.942 6.48343C17.752 7.59343 17.532 8.86343 17.302 10.0534C17.142 10.9034 16.9221 11.0534 15.9921 11.0534H10.272C8.84198 11.0534 7.42202 11.0534 6.00202 11.0534C5.85838 11.0457 5.71463 11.0664 5.57905 11.1145C5.44346 11.1625 5.31873 11.2369 5.21204 11.3334C4.77204 11.9434 5.04201 12.4134 5.80201 12.4134H15.082C15.3837 12.4015 15.6858 12.425 15.9821 12.4834C16.1629 12.5222 16.325 12.6218 16.4414 12.7655C16.5578 12.9092 16.6216 13.0885 16.6221 13.2734C16.6205 13.4717 16.5497 13.6632 16.4219 13.8148C16.2941 13.9663 16.1172 14.0684 15.922 14.1034C15.6495 14.1339 15.3745 14.1339 15.1021 14.1034L9.81202 14.1434Z"
            fill="currentColor"
          />
          <path
            d="M5.34344 17.7285C4.9924 17.708 4.66267 17.5533 4.42254 17.2964C4.18241 17.0395 4.05023 16.7001 4.0534 16.3485C4.06338 16.0862 4.15079 15.8326 4.30462 15.6198C4.45846 15.4071 4.67188 15.2446 4.9179 15.1529C5.16392 15.0612 5.43149 15.0443 5.68707 15.1045C5.94264 15.1646 6.17476 15.2991 6.35406 15.4909C6.53336 15.6827 6.65184 15.9232 6.69464 16.1823C6.73743 16.4413 6.70261 16.7073 6.59454 16.9465C6.48647 17.1858 6.30998 17.3878 6.08734 17.5269C5.86469 17.6661 5.60588 17.7362 5.34344 17.7285Z"
            fill="white"
          />
          <path
            d="M14.6365 15.0007C14.9886 15.016 15.3214 15.1654 15.5668 15.4183C15.8123 15.6712 15.9517 16.0084 15.9565 16.3607C15.946 16.7268 15.7926 17.0742 15.529 17.3284C15.2654 17.5827 14.9127 17.7235 14.5465 17.7207C14.1858 17.7088 13.8446 17.5541 13.598 17.2906C13.3514 17.0271 13.2195 16.6764 13.2315 16.3157C13.2434 15.955 13.3981 15.6139 13.6616 15.3673C13.9251 15.1207 14.2758 14.9888 14.6365 15.0007V15.0007Z"
            fill="white"
          />
        </svg>
      ) : props.getName === 'hamburger' ? (
        <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <line x1="1" y1="1" x2="23" y2="1" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
          <line x1="1" y1="9" x2="23" y2="9" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
          <line x1="1" y1="17" x2="23" y2="17" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
        </svg>
      ) : props.getName === 'close' ? (
        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M1.00537 0.638428L8.4451 8.07816M15.8848 15.5179L8.4451 8.07816M8.4451 8.07816L15.8848 0.638428L1.00537 15.5179"
            stroke="currentColor"
          />
        </svg>
      ) : props.getName === 'logout' ? (
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none" viewBox="0 0 15 15">
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M7.5 5 10 7.5m0 0L7.5 10M10 7.5H1.875m.211-3.125a6.25 6.25 0 1 1 0 6.25"
          />
        </svg>
      ) : props.getName === 'logoutMobile' ? (
        <img className="w-5" src="./../static/images/logoutmobile.png"></img>
      ) : (
        'No Condition Matched'
      )}
    </>
  );
};

export default SvgIcon;
