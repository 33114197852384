import React, { useEffect, useState } from 'react'
import Icon from './Icon'
import { ArrowPathIcon, CheckCircleIcon } from '@heroicons/react/24/outline'
import Pagination from './Pagination'
import Parser from 'html-react-parser';


const DataTableCustom = (props) => {
    const [currentIndex, setCurrentIndex] = useState(1);
    const [pageLength, setPageLength] = useState(20);
    const [totalRecords, setTotalRecords] = useState(0);
    const [DisplayRows, setDisplayRows] = useState([])
    const nextPage = () => {
        setCurrentIndex(currentIndex + 1)
    }
    const prevPage = () => {
        if (currentIndex > 1) {
            setCurrentIndex(currentIndex - 1)
        }
    }
    const changePage = (value) => {
        setCurrentIndex(value)
    }
    useEffect(() => {
        if (props.rows) {
            setDisplayRows(props.rows.slice((currentIndex - 1) * pageLength, currentIndex * pageLength))
            setTotalRecords(props.rows.length)
        }
    }, [props.rows, currentIndex])
    return (
        <div class="overflow-x-auto border mt-4 border-primary-200 rounded-lg shadow">
            <table>
                {
                    props.columns
                }
                {
                    DisplayRows
                }
            </table>
            <Pagination
                pageLength={pageLength}
                totalRecords={totalRecords}
                currentIndex={currentIndex}
                changePage={changePage}
                nextPage={nextPage}
                prevPage={prevPage}
                setCurrentIndex={setCurrentIndex}
            />
        </div>
    )
}

export default DataTableCustom