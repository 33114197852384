import React from 'react'
import './../styles/badge.scss';

const Badge = (props) => {
    return (
        <div >
            <span className={props.view} >
                {props.dot && <div className="dot"></div>}
                {props.title}
            </span>
        </div >
    )
}

export default Badge
