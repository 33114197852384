import React, { useEffect, useState } from 'react'
import LayoutMain from '../components/LayoutMain'
import { useMutation, useQuery } from 'react-query'
import { AllBrandList, CreateShopifyBrandService } from '../services/service'
import { brandSchema } from '../utils/formSchema'
import { useForm } from 'react-hook-form'
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from 'react-redux'
import { openSnack } from '../ConfigureStore/snackbarData'
import Button from '../components/Button';
import SelectSearch from '../components/SelectSearch'
import { loadToaster, loadingDecider } from './../ConfigureStore/globalLoader';

const Brands = () => {
    const dispatch = useDispatch();
    const { data: brands, isLoading: brandsLoading, refetch: brandsRefetch } = useQuery(['brandsData'], AllBrandList)
    const { mutate: createBrandShopifyMutate, isLoading: createBrandShopifyLoading } = useMutation(CreateShopifyBrandService)
    const [brandSelected, setBrandSelected] = useState([])
    const [brandOptions, setBrandOptions] = useState([])
    const [brandQuery, setBrandQuery] = useState('')
    const {
        control,
        register,
        handleSubmit,
        getValues,
        reset,
        formState: { errors }
    } = useForm({ resolver: yupResolver(brandSchema) })
    useEffect(() => {
        const payload = {
            ...getValues(),
            brand: brandSelected,
        }
        reset(payload);
    }, [brandSelected])
    const handleBrandShopify = (data) => {
        const payload = {
            brand: data?.brand?.id,
            brand_name: data?.brand_name,
            token: data?.token
        }
        createBrandShopifyMutate(payload, createBrandShopifyFunctions)
    }
    useEffect(() => {
        if (errors?.brand?.id) {

            dispatch(loadToaster({
                message: errors?.brand?.id?.message,
                type: 'error',
                transition: 'bounce'
            }));
        }
        if (errors?.brand?.name) {

            dispatch(loadToaster({
                message: errors?.brand?.name?.message,
                type: 'error',
                transition: 'bounce'
            }));
        }
        if (errors?.brand_name) {
            dispatch(loadToaster({
                message: errors?.brand_name?.message,
                type: 'error',
                transition: 'bounce'
            }));
        }
        if (errors?.token) {
            dispatch(loadToaster({
                message: errors?.token?.message,
                type: 'error',
                transition: 'bounce'
            }));
        }
    }, [errors])
    const createBrandShopifyFunctions = {
        onSuccess: ((res) => {
            if (res?.data?.error) {
                dispatch(loadToaster({
                    message: "something went wrong",
                    type: 'error',
                    transition: 'bounce'
                }));
                return;
            }
            dispatch(loadToaster({
                message: "successfully created",
                type: 'success',
                transition: 'bounce'
            }));
            brandsRefetch();
            reset();
        }),
        onError: ((err) => {
            dispatch(loadToaster({
                message: "something went wrong",
                type: 'error',
                transition: 'bounce'
            }));
        })

    }
    useEffect(() => {
        if (brands?.data?.length > 0) {
            const brandData = brands?.data?.map((item, key) => ({
                id: item?.id,
                name: item?.name
            }))
            setBrandOptions(brandData)
        }
    }, [brands])
    const overallLoading = brandsLoading || createBrandShopifyLoading;
    useEffect(() => {
        if (overallLoading) {
            dispatch(loadingDecider(true))
        }
        else {
            dispatch(loadingDecider(false))
        }
    }, [overallLoading])


    return (
        <LayoutMain>

            <div className="heading-container">
                <div className="heading-main">
                    <h3>
                        SHOPIFY STORE CREDENTIALS
                    </h3>
                </div>
            </div >
            <div className="px-8 ">
                <div className="border-b flex gap-4"></div>
                <div className="mt-4 border border-primary-200 rounded-lg shadow p-5 w-1/2">
                    <form onSubmit={handleSubmit(handleBrandShopify)} className="space-y-4 mt-2">
                        <div>
                            <label for="brand_name">Stores</label>
                            <SelectSearch
                                listItems={brandOptions}
                                selected={brandSelected}
                                setSelected={setBrandSelected}
                                placeholder={'Select stores...'}
                                query={brandQuery}
                                setQuery={setBrandQuery}
                                fullList={false}
                            />
                        </div>
                        <div>
                            <label for="brand_name">Store Slug</label>
                            <input  {...register('brand_name')} type="text" className="w-full my-1 placeholder:font-medium" placeholder="Enter store slug" />
                        </div>
                        <div>
                            <label for="brand_name">Token</label>
                            <input  {...register('token')} type="text" className="w-full my-1 placeholder:font-medium" placeholder="Enter token" />
                        </div>
                        <Button type="submit" className="btn btn-primary" title='Submit' />
                    </form>
                </div>
            </div>

            {/* <div className="content-wrapper">
                <section className="content-header">
                </section>
                
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="card card-primary">
                                    <div className="card-header">
                                        <h3 className="font-weight-bold card-title">SHOPIFY STORE CREDENTIALS</h3>
                                    </div>
                
                                    <form onSubmit={handleSubmit(handleBrandShopify)}>
                                        <div className="card-body">
                                            <div className="form-group">
                                                <label>STORES</label>
                                                {!brandsLoading ?
                                                    <Controller
                                                        name="brand"
                                                        control={control}
                                                        render={({ field }) => {
                                                            return (
                                                                <Select
                                                                    {...field}
                                                                    placeholder="Select a Store"
                                                                    options={brandOptions}
                                                                    styles={customStyle}
                                                                />
                                                            )
                                                        }
                                                        }
                                                    />
                                                    :
                                                    <div className='col-12 flex justify-center'><img className="w-10" src={spinner} /></div>
                                                }
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">STORE SLUG</label>
                                                <input  {...register('brand_name')} type="text" className="form-control" placeholder="STORE SLUG" />
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="exampleInputPassword1">TOKEN</label>
                                                <input {...register('token')} type="text" className="form-control" placeholder="TOKEN" />
                                            </div>
                                        </div>

                                        <div className="card-footer">
                                            {createBrandShopifyLoading ?
                                                <div className='col-4 flex justify-start'><img className="w-12" src={spinner} /></div>
                                                : <button type="submit" className="btn bg-blue btn-primary">Submit</button>
                                            }
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section >
            </div > */}
        </LayoutMain >
    )
}

export default Brands