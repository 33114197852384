import { Combobox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/outline'
import React, { Fragment, useState } from 'react'

const SelectSearch = (props) => {
    const filteredPeople =
        props.query === ''
            ? props.fullList ? props.listItems : props.listItems.slice(0, 10)
            : props.listItems.filter((person) =>
                person.name
                    .toLowerCase()
                    .replace(/\s+/g, '')
                    .includes(props.query.toLowerCase().replace(/\s+/g, ''))
            ).slice(0, 10)
    return (

        <Combobox value={props.selected} onChange={props.setSelected}>
            <div className="relative">
                <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left border border-primary-300 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2  sm:text-sm">
                    <Combobox.Input
                        placeholder={props.placeholder}
                        className="w-full border-none py-2 pl-3 leading-5 text-gray-900 placeholder:font-medium"
                        displayValue={(person) => person.name}
                        onChange={(event) => { if (event.target.value === '') { props.setSelected([]) } props.setQuery(event.target.value) }}
                    />
                    <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2 ">
                        <ChevronUpDownIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                        />
                    </Combobox.Button>
                </div>
                <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    afterLeave={() => props.setQuery('')}
                >
                    <Combobox.Options className={`${props.upperBox ? 'bottom-11' : ''} absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-50`} >
                        {filteredPeople.length === 0 && props.query !== '' ? (
                            <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                Nothing found.
                            </div>
                        ) : (
                            filteredPeople.map((person) => (
                                <Combobox.Option
                                    key={person.id}
                                    className={({ active }) =>
                                        `text-xs relative cursor-default select-none py-1 pl-10 pr-4 border-b last:border-b-0 ${active ? 'hover:bg-primary-25 text-primary-600' : 'text-gray-600'
                                        }`
                                    }
                                    value={person}
                                >
                                    {({ selected, active }) => (
                                        <>
                                            <span
                                                className={`block truncate  ${selected ? 'font-medium' : 'font-normal'
                                                    }`}
                                            >
                                                {person.name}
                                            </span>
                                            {selected ? (
                                                <span
                                                    className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-primary-900' : 'text-primary-900'
                                                        }`}
                                                >
                                                    <CheckIcon className="h-4 w-5 text-primary-600" aria-hidden="true" />
                                                </span>
                                            ) : null}
                                        </>
                                    )}
                                </Combobox.Option>
                            ))
                        )}
                    </Combobox.Options>
                </Transition>
            </div>
        </Combobox>

    )
}

export default SelectSearch
