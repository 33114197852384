import React, { useEffect, useState } from 'react'
import LayoutLogin from '../components/LayoutLogin';
import { Link, useNavigate } from 'react-router-dom';
import spinner from './../assets/images/spinner.gif'
import MedusaLogo from './../assets/medusa.png'
import { AuthOtpService, authService } from '../services/service';
import { useDispatch, useSelector } from 'react-redux';
import { authSchema } from "../utils/formSchema";
import { useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import Cookies from 'js-cookie';
import Button from '../components/Button';
import { loadToaster, loadingDecider } from '../ConfigureStore/globalLoader';
import { useMutation } from 'react-query'

const Authentication2Fa = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { mutate: AuthOtpServiceMutate, isLoading: AuthOtpServiceLoading } = useMutation(AuthOtpService)
  const customer = useSelector(state => state.customerLogin);
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({ resolver: yupResolver(authSchema) })

  if (errors) {
    if (errors?.authOTP) {
      dispatch(loadToaster({
        message: errors?.authOTP?.message,
        type: 'error',
        transition: 'bounce'
      }))
    }
  }

  const onSubmitAuth = (data) => {
    const payload = {
      token: data.authOTP,
      id: customer?.customer?.id,
      temp_token: customer?.customer?.temp_secret?.base32
    }
    AuthOtpServiceMutate(payload, AuthOTPFunctions);
  }
  const AuthOTPFunctions = {
    onSuccess: (data) => {
      dispatch(loadToaster({
        message: "Login Successfully",
        type: 'success',
        transition: 'bounce'
      }))
      navigate('/products')
    },
    onError: (error) => {
      dispatch(loadToaster({
        message: "Invalid Credentials",
        type: 'error',
        transition: 'bounce'
      }));
    }
  }
  const overallLoading = AuthOtpServiceLoading;
  useEffect(() => {
    if (overallLoading) {
      dispatch(loadingDecider(true))
    }
    else {
      dispatch(loadingDecider(false))
    }
  }, [AuthOtpServiceLoading])


  return (
    <>
      <LayoutLogin>
        <div
          className="bg-gradient-to-bl from-primary-500 via-primary-200 to-primary-100 h-screen w-full flex items-center justify-center">
          <div className="bg-primary-50 p-5 shadow-md rounded-lg w-96 space-y-10">
            <div className="flex items-center justify-center gap-2">
              <img src={MedusaLogo} alt="" className="w-12 h-12" />
              <h3 className="text-primary-700">Medusa App</h3>
            </div>
            {
              customer?.customer?.QRscan ?
                <form method="post" onSubmit={handleSubmit(onSubmitAuth)} className="space-y-4">
                  <div>
                    <label for="email" className="font-light">QR Scan Google Authenticator</label>
                    <div className='flex items-center  justify-center mt-2'>
                      <img src={customer?.customer?.QRscan} />
                    </div>
                    <div className='mt-2'>
                      <label for="email" className="font-light">Google Authenticator Code</label>
                      <input className="w-full mt-1" {...register('authOTP')} type="text" name="authOTP" required autofocus autocomplete />
                    </div>
                  </div>
                  <Button type="submit" className="btn btn-primary w-full" title="Validate" />
                </form>
                :
                <form method="post" onSubmit={handleSubmit(onSubmitAuth)} className="space-y-4">
                  <div>
                    <label for="email" className="font-light">Google Authenticator Code</label>
                    <input className="w-full mt-1" {...register('authOTP')} type="text" name="authOTP" required autofocus autocomplete />
                  </div>
                  <Button type="submit" className="btn btn-primary w-full" title="Validate" />
                </form>
            }
          </div>
        </div>
      </LayoutLogin >
    </>
  )
}

export default Authentication2Fa