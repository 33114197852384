import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import './App.css';
import Dashboard from './pages/Dashboard';
import ExceptPage from './pages/ExceptPage';
import Login from './pages/Login';
import ForgetPassword from './pages/ForgetPassword';
import PrivateRoute from './components/PrivateRoute';
import Brands from './pages/Brands';
import Locators from './pages/Locators';
import LocatorList from './pages/LocatorsList';
import BrandsList from './pages/BrandsList';
import BrandLocator from './pages/BrandLocator';
import ErrorsList from './pages/ErrorsList';
import LocatorsStyle from './pages/LocatorsStyle';
import PulseLoader from "react-spinners/PulseLoader";
import { ToastContainer } from 'react-toastify';
import { useSelector } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import Authentication2Fa from './pages/Authentication2Fa';
import UserList from './pages/UserList';


function App() {
  const loadingDecider = useSelector((state) => state.GlobalLoader)
  return (
    <BrowserRouter>
      {
        loadingDecider.loading ?
          <div class="fixed inset-0 flex items-center justify-center z-50 bg-primary-700/60 backdrop-blur-md">
            <PulseLoader color="#EAECF0" />
          </div> : null
      }
      <ToastContainer />
      <Routes>
        <Route path="/products" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
        <Route path="/" element={<Navigate to={'/login'} />} />
        <Route path="/brands" >
          <Route index element={<PrivateRoute><Brands /></PrivateRoute>} />
          <Route path="list" element={<PrivateRoute><BrandsList /></PrivateRoute>} />
        </Route>
        <Route path="/locators" >
          <Route index element={<PrivateRoute><Locators /></PrivateRoute>} />
          <Route path="list" element={<PrivateRoute><LocatorList /></PrivateRoute>} />
          <Route path="style" element={<PrivateRoute><LocatorsStyle /></PrivateRoute>} />
        </Route>
        <Route path="/errors" >
          <Route path="list" element={<PrivateRoute><ErrorsList /></PrivateRoute>} />
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/auth/:id" element={<Authentication2Fa />} />
        <Route path='/brandLocator/:brandName' element={<BrandLocator />} />
        <Route path='//users/list' element={<UserList />} />
        <Route path="/forget-password" element={<ForgetPassword />} />
        <Route path="*" element={<ExceptPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
