import axios from "axios"
import { GetTokenRequestService } from "../services/service"


export default async function smartyRequest(method, path = "", payload = {}) {

  const resp = await GetTokenRequestService();
  let baseURL = resp?.data?.SMARTY_TOKEN + `${payload?.keyword}`

  const client = axios.create({ baseURL })
  const options = {
    method,
    url: path,
  }
  return client(options)
}

